import React, { FC } from 'react';

import { Box, Button } from '@material-ui/core';

import Typography from 'ui/common/Typography';

import { useRenderPrice } from 'hooks';

import { useSelector } from 'react-redux';
import { getLang } from 'store/modules/config/selectors';
import useStyles from './styles';

import { IContinueToCart } from './types';

const ContinueToCart: FC<IContinueToCart> = (props) => {
  const { price, onClick, disabled, children, ...rest } = props;
  const classes = useStyles(props);

  const renderPrice = useRenderPrice();
  const lang = useSelector(getLang);

  return (
    <Button
      classes={{ root: classes.rootButton, label: classes.label }}
      variant="contained"
      color="primary"
      onClick={onClick}
      disabled={disabled}
      TouchRippleProps={{ classes: { child: classes.buttonRipple } }}
      {...rest}
    >
      <Box display="flex" flex={1} justifyContent="center" alignItems="center">
        <Typography
          color="light"
          variant="body1"
          fontWeight="medium"
          className="text-transform-initial"
        >
          {children || 'button.continueToCheckout'}
        </Typography>
      </Box>

      <Box className={classes.price}>
        <Typography
          color="light"
          variant="body2"
          fontWeight={lang === 'ru' ? 'semiBoldRoboto' : 'semiBold'}
        >
          {renderPrice(price)}
        </Typography>
      </Box>
    </Button>
  );
};

export default ContinueToCart;
