import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';

import { TProductCardViewMode, TStylesProductCardViewMode } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesProductCardViewMode, { viewMode: TProductCardViewMode }> =>
    createStyles({
      multipleViewModeBtn: ({ viewMode }) => ({
        fontSize: '18px',
        minWidth: 'unset',
        padding: 0,
        marginRight: theme.spacing(3 / 8),
        color: viewMode === 'multiple' ? theme.palette.primary.main : theme.palette.grey[400],
      }),
      singleViewModeBtn: ({ viewMode }) => ({
        fontSize: '18px',
        minWidth: 'unset',
        padding: 0,
        marginLeft: theme.spacing(3 / 8),
        color: viewMode === 'single' ? theme.palette.primary.main : theme.palette.grey[400],
      }),
    }),
);
