import { createStyles, makeStyles, StyleRules } from '@material-ui/core';
import { TStylesICategoryBannerComponent } from 'components/common/CategoryBanner/types';

export default makeStyles(
  (theme): StyleRules<TStylesICategoryBannerComponent, { gutter?: number }> =>
    createStyles({
      wrapper: ({ gutter }) => ({
        width: '100%',
        display: 'flex',
        paddingBottom: gutter,
      }),
      image: {
        width: '100%',
        maxHeight: '100%',
        borderRadius: theme.spacing(1),
        objectFit: 'cover',
      },
    }),
);
