import React, { FC, useCallback, useState } from 'react';

import { useSelector } from 'react-redux';

import { Box } from '@material-ui/core';

import LoadPreviousOrder from 'ui/mobile/buttons/LoadPreviousOrder';
import { ILoadPreviousOrderFixed } from 'components/mobile/LoadPreviousOrderFixed/types';

import { useAutoHideOnKeyboardOpen, useCart, useDialog } from 'hooks';

import { authSelectors, LOAD_PREVIOUS_ORDERS_DIALOG } from 'store';

import useStyles from './styles';

const LoadPreviousOrderFixed: FC<ILoadPreviousOrderFixed> = () => {
  const classes = useStyles();

  const [isVisible, setIsVisible] = useState(true);

  const isLoggedIn = useSelector(authSelectors.isLoggedIn);

  const keyboardClosed = useAutoHideOnKeyboardOpen();

  // TODO: need to create useOrder hook to avoid previous order fetching (review this todo)
  const { uniqueItemCount } = useCart();
  const { showDialog } = useDialog();

  const onClose = useCallback(() => {
    setIsVisible(false);
  }, []);

  const handleClick = useCallback(() => {
    showDialog({
      dialogType: LOAD_PREVIOUS_ORDERS_DIALOG,
    });
  }, [showDialog]);

  if (!isLoggedIn || uniqueItemCount !== 0 || !isVisible || !keyboardClosed) {
    return null;
  }
  return (
    <Box zIndex={5} className={classes.buttonWrp}>
      <LoadPreviousOrder onClick={handleClick} onClose={onClose} />
    </Box>
  );
};

export default LoadPreviousOrderFixed;
