import React, { FC, useContext } from 'react';

import { useTranslation } from 'react-i18next';

import { Box, CardMedia } from '@material-ui/core';

import { MainLayoutContext } from 'ui/common/layout/MainLayout/MainLayoutProvider/MainLayoutContext';
import Typography from 'ui/common/Typography';

import { useWebsiteDetails } from 'hooks';
import classNames from 'classnames';
import useStyles from './styles';
import { INoSearchResults } from './types';

const NoSearchResults: FC<INoSearchResults> = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const websiteDetails = useWebsiteDetails();

  const mainLayoutContext = useContext(MainLayoutContext);

  return (
    <Box textAlign={'center'}>
      <Box mb={2}>
        <CardMedia
          src={websiteDetails.theme.images.searchFailure}
          component="img"
          className={classes.searchFailed}
          alt={t('error.itemsNotFoundDescription')}
        />
      </Box>
      <Box>
        <Typography
          color={'secondary'}
          variant={'body1'}
          className={classNames(classes.title, 'font-family-heebo')}
        >
          {t('error.itemsNotFoundTitle', {
            searchQuery: mainLayoutContext.searchContext.searchQuery,
          })}
        </Typography>

        <Typography color={'textSecondary'} variant={'body1'} className={classes.description}>
          {'error.itemsNotFoundDescription'}
        </Typography>
      </Box>
    </Box>
  );
};

export default NoSearchResults;
