import React, { FC, useCallback, useContext, useMemo } from 'react';
import DropDown, { TDropDownOption } from 'ui/common/inputs/DropDown';
import { Box } from '@material-ui/core';
import Typography from 'ui/common/Typography';
import { useTranslation } from 'react-i18next';
import { MainLayoutContext } from 'ui/common/layout/MainLayout';
import { ICategoryNameAndSort } from './types';
import useStyles from './styles';

const CategoryNameAndSort: FC<ICategoryNameAndSort> = ({
  categoryName,
  onSortOrderChange,
  selectedSortOrder,
  sortOptions = 'cross-category',
  variant,
  hideDropDown,
}) => {
  const mainLayoutContext = useContext(MainLayoutContext);
  const { t } = useTranslation();

  const classes = useStyles({
    variant,
    isSearchValue: !!mainLayoutContext.searchContext.searchQuery,
  });

  const sortOrdersOptions = useMemo<TDropDownOption[]>(() => {
    const allSortOptions = [
      {
        id: 1,
        value: 1,
        text: t('shoppingPage.sort.sortByName'),
        ariaLabel: `${t('shoppingPage.sort.sortBy')}: ${t('shoppingPage.sort.sortByName')}`,
        selectedText: `${t('shoppingPage.sort.sortBy')}: ${t('shoppingPage.sort.sortByName')}`,
        variant: 'cross-category',
      },
      {
        id: 2,
        value: 2,
        text: t('shoppingPage.sort.sortByCategoryAndName'),
        ariaLabel: `${t('shoppingPage.sort.sortBy')}: ${t(
          'shoppingPage.sort.sortByCategoryAndName',
        )}`,
        selectedText: `${t('shoppingPage.sort.sortBy')}: ${t(
          'shoppingPage.sort.sortByCategoryAndName',
        )}`,
        variant: 'cross-category',
      },
      {
        id: 3,
        value: 3,
        text: t('shoppingPage.sort.sortByPriceAsc'),
        ariaLabel: `${t('shoppingPage.sort.sortBy')}: ${t('shoppingPage.sort.sortByPriceAsc')}`,
        selectedText: `${t('shoppingPage.sort.sortBy')}: ${t('shoppingPage.sort.sortByPriceAsc')}`,
        variant: 'cross-category',
      },
      {
        id: 4,
        value: 4,
        text: t('shoppingPage.sort.sortByPriceDesc'),
        ariaLabel: `${t('shoppingPage.sort.sortBy')}: ${t('shoppingPage.sort.sortByPriceDesc')}`,
        selectedText: `${t('shoppingPage.sort.sortBy')}: ${t('shoppingPage.sort.sortByPriceDesc')}`,
        variant: 'cross-category',
      },
      {
        id: 5,
        value: 5,
        text: t('shoppingPage.sort.sortByPromoted'),
        ariaLabel: `${t('shoppingPage.sort.sortBy')}: ${t('shoppingPage.sort.sortByPromoted')}`,
        selectedText: `${t('shoppingPage.sort.sortBy')}: ${t('shoppingPage.sort.sortByPromoted')}`,
        variant: 'cross-category',
      },
      {
        id: 6,
        value: 6,
        text: t('shoppingPage.sort.sortByName'),
        ariaLabel: `${t('shoppingPage.sort.sortBy')}: ${t('shoppingPage.sort.sortByName')}`,
        selectedText: `${t('shoppingPage.sort.sortBy')}: ${t('shoppingPage.sort.sortByName')}`,
        variant: 'in-category',
      },
      {
        id: 7,
        value: 7,
        text: t('shoppingPage.sort.sortByPriceAsc'),
        ariaLabel: `${t('shoppingPage.sort.sortBy')}: ${t('shoppingPage.sort.sortByPriceAsc')}`,
        selectedText: `${t('shoppingPage.sort.sortBy')}: ${t('shoppingPage.sort.sortByPriceAsc')}`,
        variant: 'in-category',
      },
      {
        id: 8,
        value: 8,
        text: t('shoppingPage.sort.sortByPriceDesc'),
        ariaLabel: `${t('shoppingPage.sort.sortBy')}: ${t('shoppingPage.sort.sortByPriceDesc')}`,
        selectedText: `${t('shoppingPage.sort.sortBy')}: ${t('shoppingPage.sort.sortByPriceDesc')}`,
        variant: 'in-category',
      },
      {
        id: 9,
        value: 9,
        text: t('shoppingPage.sort.sortByPromoted'),
        ariaLabel: `${t('shoppingPage.sort.sortBy')}: ${t('shoppingPage.sort.sortByPromoted')}`,
        selectedText: `${t('shoppingPage.sort.sortBy')}: ${t('shoppingPage.sort.sortByPromoted')}`,
        variant: 'in-category',
      },
      {
        id: 10,
        value: 10,
        text: t('shoppingPage.sort.sortBySearchRelevancy'),
        ariaLabel: `${t('shoppingPage.sort.sortBy')}: ${t(
          'shoppingPage.sort.sortBySearchRelevancy',
        )}`,
        selectedText: `${t('shoppingPage.sort.sortBy')}: ${t(
          'shoppingPage.sort.sortBySearchRelevancy',
        )}`,
        variant: 'search',
      },
      {
        id: 11,
        value: 11,
        text: t('shoppingPage.sort.sortByCategoryAndName'),
        ariaLabel: `${t('shoppingPage.sort.sortBy')}: ${t(
          'shoppingPage.sort.sortByCategoryAndName',
        )}`,
        selectedText: `${t('shoppingPage.sort.sortBy')}: ${t(
          'shoppingPage.sort.sortByCategoryAndName',
        )}`,
        variant: 'search',
      },
      {
        id: 12,
        value: 12,
        text: t('shoppingPage.sort.sortByName'),
        ariaLabel: `${t('shoppingPage.sort.sortBy')}: ${t('shoppingPage.sort.sortByName')}`,
        selectedText: `${t('shoppingPage.sort.sortBy')}: ${t('shoppingPage.sort.sortByName')}`,
        variant: 'search',
      },
      {
        id: 13,
        value: 13,
        text: t('shoppingPage.sort.sortByPriceAsc'),
        ariaLabel: `${t('shoppingPage.sort.sortBy')}: ${t('shoppingPage.sort.sortByPriceAsc')}`,
        selectedText: `${t('shoppingPage.sort.sortBy')}: ${t('shoppingPage.sort.sortByPriceAsc')}`,
        variant: 'search',
      },
      {
        id: 14,
        value: 14,
        text: t('shoppingPage.sort.sortByPriceDesc'),
        ariaLabel: `${t('shoppingPage.sort.sortBy')}: ${t('shoppingPage.sort.sortByPriceDesc')}`,
        selectedText: `${t('shoppingPage.sort.sortBy')}: ${t('shoppingPage.sort.sortByPriceDesc')}`,
        variant: 'search',
      },
      {
        id: 15,
        value: 15,
        text: t('shoppingPage.sort.sortByPromoted'),
        ariaLabel: `${t('shoppingPage.sort.sortBy')}: ${t('shoppingPage.sort.sortByPromoted')}`,
        selectedText: `${t('shoppingPage.sort.sortBy')}: ${t('shoppingPage.sort.sortByPromoted')}`,
        variant: 'search',
      },
    ];

    return allSortOptions.filter((sortOption) => sortOption.variant === sortOptions);
  }, [sortOptions, t]);

  const onDropDownOptionClick = useCallback(
    (sortOrder) => {
      if (onSortOrderChange) onSortOrderChange(sortOrder);
    },
    [onSortOrderChange],
  );

  return (
    <Box className={classes.categoryWrapper}>
      <Box className={classes.categoryTextWrapper}>
        <Typography
          variant="h1"
          color="secondary"
          className={classes.selectedCategoryName}
          isTranslate={false}
        >
          {mainLayoutContext.searchContext.searchQuery?.trim()
            ? t('shoppingPage.searchResults')
            : categoryName}
        </Typography>
      </Box>
      {!hideDropDown && (
        <Box className={classes.sortDropdownWrapper}>
          <DropDown
            dropDownOptions={sortOrdersOptions}
            value={selectedSortOrder}
            onChange={(e) => onDropDownOptionClick(e.target.value as number)}
          />
        </Box>
      )}
    </Box>
  );
};

export default CategoryNameAndSort;
