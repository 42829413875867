import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { Box, useTheme } from '@material-ui/core';

import { useCategoryMenu, useMobile } from 'hooks';

import { MainLayoutContext } from 'ui/common/layout/MainLayout';
import NoSearchResults from 'components/common/NoSearchResults';
import useProducts from 'hooks/useProducts';
import StoreProductsListSSR from 'components/common/StoreProductsListSSR';
import CategoryNameAndSort, {
  ICategoryNameAndSort,
  TOnSortOrderChange,
} from 'components/common/CategoryNameAndSort';
import StoreProductsVirtualizedList from 'components/common/StoreProductsVirtualizedList';

import ProductCardViewMode from 'ui/mobile/buttons/ProductCardViewMode';
import { useSelector } from 'react-redux';
import { getHomePageUiStyles } from 'store/modules/uiStyles/selectors';
import { promotionsSelectors } from 'store';
import { TCategoryMenuItem } from 'ui/desktop/CategoryMenu';
import Typography from 'ui/common/Typography';
import Icon from 'ui/common/icons/Icon';
import { useRouter } from 'next/router';
import Link from 'ui/common/Link';
import useStyles from './styles';
import { IStoreCardsList, TOnSubCategorySort } from './types';

const StoreProductsWrapper: FC<IStoreCardsList> = () => {
  const mainLayoutContext = useContext(MainLayoutContext);
  const { searchQuery } = mainLayoutContext.searchContext;

  const { isMobile } = useMobile();

  const { hidePromotionsBanner } = useSelector(getHomePageUiStyles);

  const { promotionsForBanners } = useSelector(promotionsSelectors.promotionsData);

  const { activeMenuItem, menuItems } = useCategoryMenu();

  const theme = useTheme();

  const [selectedSortOrder, setSelectedSortOrder] = useState(activeMenuItem?.id === -1 ? 2 : 9);
  const [prevSubCategoryLink, setPrevSubCategoryLink] = useState<TCategoryMenuItem | null>(null);
  const [nextSubCategoryLink, setNextSubCategoryLink] = useState<TCategoryMenuItem | null>(null);
  const [isHideProductSortDropDown, setIsHideProductSortDropDown] = useState<boolean>(
    !!activeMenuItem?.subMenuItems,
  );
  const [productSortDropDownOptions, setProductSortDropDownOptions] = useState<
    ICategoryNameAndSort['sortOptions']
  >(activeMenuItem?.id === -1 ? 'cross-category' : 'in-category');

  const router = useRouter();

  const { sortedProducts, handleSubCategorySort, resetSubCategoryAndSubCategoryOrder } =
    useProducts(selectedSortOrder, activeMenuItem, searchQuery);

  const classes = useStyles({
    isOpenedTopCart: mainLayoutContext.isOpenedTopCart,
    hidePromotionsBanner,
    promotionsForBannersLength: promotionsForBanners.length,
  });

  const handleSortOrderChanged = useCallback<TOnSortOrderChange>((newSortOrder) => {
    setSelectedSortOrder(newSortOrder);
  }, []);

  const prevAndNextSubMenuLinks = useMemo(() => {
    return (
      <Box display="flex" justifyContent="space-between" marginBottom={isMobile ? 50 / 8 : 0}>
        <Box>
          {prevSubCategoryLink && (
            <Link href={prevSubCategoryLink.link.href} className={classes.link}>
              <Icon
                name={`icon-arrow-${theme.direction === 'rtl' ? 'right' : 'left'}`}
                color="primary"
                classes={{ root: classes.iconArrowRight }}
              />
              <Typography variant="body1" className={classes.subCategoryLink}>
                {prevSubCategoryLink.text}
              </Typography>
            </Link>
          )}
        </Box>
        <Box>
          {nextSubCategoryLink && (
            <Link href={nextSubCategoryLink.link.href} className={classes.link}>
              <Typography variant="body1" className={classes.subCategoryLink}>
                {nextSubCategoryLink.text}
              </Typography>
              <Icon
                name={`icon-arrow-${theme.direction === 'rtl' ? 'left' : 'right'}`}
                color="primary"
                classes={{ root: classes.iconArrowLeft }}
              />
            </Link>
          )}
        </Box>
      </Box>
    );
  }, [
    classes.iconArrowLeft,
    classes.iconArrowRight,
    classes.link,
    classes.subCategoryLink,
    isMobile,
    nextSubCategoryLink,
    prevSubCategoryLink,
    theme.direction,
  ]);

  const isSearchQuery = useMemo(() => {
    return searchQuery && !!searchQuery.trim() && searchQuery.length;
  }, [searchQuery]);

  const categoryNameAndSort = useMemo(() => {
    if (!activeMenuItem) return null;

    return (
      <CategoryNameAndSort
        categoryName={activeMenuItem.text}
        onSortOrderChange={handleSortOrderChanged}
        selectedSortOrder={selectedSortOrder}
        hideDropDown={isHideProductSortDropDown}
        sortOptions={productSortDropDownOptions}
        variant={activeMenuItem.subMenuItems ? 'fatherWithChild' : 'fatherWithoutChild'}
      />
    );
  }, [
    activeMenuItem,
    handleSortOrderChanged,
    isHideProductSortDropDown,
    productSortDropDownOptions,
    selectedSortOrder,
  ]);

  const handleSubCategory = useCallback<TOnSubCategorySort>(
    (newSortOrder, category) => {
      handleSubCategorySort(newSortOrder, category);
    },
    [handleSubCategorySort],
  );

  const productsList = useMemo(() => {
    if (typeof window === 'undefined' && sortedProducts) {
      return <StoreProductsListSSR storeProducts={sortedProducts} />;
    }

    if (!activeMenuItem) return null;

    return sortedProducts && sortedProducts.length ? (
      <StoreProductsVirtualizedList
        storeProducts={sortedProducts}
        onSubCategorySort={handleSubCategory}
        categoryHeaders={!!activeMenuItem.subMenuItems && !searchQuery}
      />
    ) : (
      isSearchQuery && <NoSearchResults />
    );
  }, [activeMenuItem, handleSubCategory, isSearchQuery, searchQuery, sortedProducts]);

  useEffect(() => {
    if (mainLayoutContext.searchContext.searchQuery && router.pathname !== '/search/[query]') {
      mainLayoutContext.searchContext.handleClear();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.asPath]);

  useEffect(() => {
    if (isSearchQuery && sortedProducts && !sortedProducts.length) {
      setIsHideProductSortDropDown(true);
    }
  }, [isSearchQuery, sortedProducts]);

  useEffect(() => {
    if (isSearchQuery) {
      setSelectedSortOrder(10);
      setProductSortDropDownOptions('search');
      setIsHideProductSortDropDown(false);
      resetSubCategoryAndSubCategoryOrder();
      return;
    }

    if (activeMenuItem) {
      setSelectedSortOrder(activeMenuItem.id === -1 ? 2 : 9);
      setProductSortDropDownOptions(activeMenuItem?.id === -1 ? 'cross-category' : 'in-category');
      setIsHideProductSortDropDown(!!activeMenuItem.subMenuItems);
    }
  }, [activeMenuItem, isSearchQuery, resetSubCategoryAndSubCategoryOrder]);

  useEffect(() => {
    let nextSubCategory;
    let previousSubCategory;
    if (activeMenuItem?.parent) {
      const parentCategory = activeMenuItem?.parent;

      const indexOfCurrentSubCategory =
        parentCategory?.subMenuItems &&
        parentCategory?.subMenuItems.findIndex((subCategory) => {
          return subCategory.id === activeMenuItem.id;
        });

      if (typeof indexOfCurrentSubCategory === 'number' && parentCategory?.subMenuItems) {
        nextSubCategory =
          indexOfCurrentSubCategory === parentCategory?.subMenuItems.length - 1
            ? null
            : parentCategory?.subMenuItems[indexOfCurrentSubCategory + 1];

        previousSubCategory =
          indexOfCurrentSubCategory === 0
            ? null
            : parentCategory?.subMenuItems[indexOfCurrentSubCategory - 1];

        setPrevSubCategoryLink(previousSubCategory);
        setNextSubCategoryLink(nextSubCategory);
      }
      return;
    }
    setPrevSubCategoryLink(null);
    setNextSubCategoryLink(null);
  }, [activeMenuItem, menuItems]);

  return (
    <Box display="flex" flexDirection="row" className={classes.rootWrp}>
      <Box component="section" flex={1}>
        <Box display="flex">
          {categoryNameAndSort}
          {isMobile && (
            <Box
              ml={9 / 8}
              display="flex"
              pb={activeMenuItem?.subMenuItems && !isSearchQuery ? 0 : 20 / 8}
            >
              <ProductCardViewMode />
            </Box>
          )}
        </Box>
        <div className={classes.productListWrapper} aria-live="polite">
          {productsList}
          {!searchQuery && prevAndNextSubMenuLinks}
        </div>
      </Box>
    </Box>
  );
};

export default StoreProductsWrapper;
