import { createStyles, makeStyles, StyleRules } from '@material-ui/core';

import { TStylesLoadPreviousOrderFixed } from './types';

export default makeStyles(
  (): StyleRules<TStylesLoadPreviousOrderFixed> =>
    createStyles({
      buttonWrp: {
        width: '100%',
        position: 'sticky',
        bottom: 0,
      },
    }),
);
