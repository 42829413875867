import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';
import { TStylesNoSearchResults } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesNoSearchResults> =>
    createStyles({
      searchFailed: {
        margin: 'auto',
        width: theme.spacing(69 / 8),
        [theme.breakpoints.up('md')]: {
          width: theme.spacing(79 / 8),
        },
      },
      title: {
        fontSize: theme.spacing(26 / 8),
        fontWeight: 'bold',
        lineHeight: 1.08,
        marginBottom: theme.spacing(1),
        [theme.breakpoints.up('md')]: {
          fontSize: theme.spacing(30 / 8),
          lineHeight: 1.13,
        },
      },
      description: {
        fontSize: theme.spacing(16 / 8),
        lineHeight: 1.25,
      },
    }),
);
