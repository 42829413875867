import React, { FC, useCallback, useMemo, useState } from 'react';

import { Box, Button, CardMedia, IconButton } from '@material-ui/core';

import Icon from 'ui/common/icons/Icon';
import SaleHorizontalTag from 'ui/common/tags/SaleHorizontalTag';

import Typography from 'ui/common/Typography';

import { useCardPrice, useProduct } from 'hooks';

import { renderItemCount } from 'utils';

import CartItemQuantityAndUnitControls from 'ui/common/buttons/CartItemQuantityAndUnitControls';
import RemovedCartItemSection from 'ui/common/RemovedCartItemSection';

import { useMultiLanguage } from 'hooks/useMultiLanguage';
import { useBagOfProducts } from 'hooks/useBagOfProducts';
import { orderActions, storeProductSelectors } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import { ICartItem } from './types';
import useStyles from './styles';

const CartItem: FC<ICartItem> = (props) => {
  const { onChooseUnit, onChangeProductCount, onDeleteItem, ...storeProductDisplay } = props;

  const { outOfStockStoreProductById } = useSelector(storeProductSelectors.storeProductsData);

  const [isFocus, setIsFocus] = useState<boolean>(false);

  const classes = useStyles({
    isDeleteItem: storeProductDisplay.cartData?.orderItem.isRemoved,
    isFocus,
    ...props,
  });

  const { renderCartItemTotalPrice } = useCardPrice(storeProductDisplay.pricingSellingUnitName);

  const { showEditBasketDialog } = useBagOfProducts();

  const { renderLanguageField } = useMultiLanguage();

  const { handleProductDetailsPopup } = useProduct();

  const dispatch = useDispatch();

  const handleMouseEnter = useCallback(() => {
    setIsFocus(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setIsFocus(false);
  }, []);

  const handleDeleteOrKeepItemInCart = useCallback(() => {
    dispatch(orderActions.togglePartlyRemoveProductFromCart(storeProductDisplay.id));
  }, [dispatch, storeProductDisplay.id]);

  const pricesSectionRendered = useMemo(() => {
    return (
      <Typography component="div" fontWeight="bold" color="textPrimary" fontSize={16}>
        {renderCartItemTotalPrice(
          storeProductDisplay.cartData?.totalPrice as number,
          storeProductDisplay.cartData?.originalTotalPrice,
        )}
      </Typography>
    );
  }, [
    renderCartItemTotalPrice,
    storeProductDisplay.cartData?.totalPrice,
    storeProductDisplay.cartData?.originalTotalPrice,
  ]);

  const renderQuantity = useMemo(() => {
    return (
      <Box>
        <Box className={classes.quantityButton}>
          <CartItemQuantityAndUnitControls
            onChangeProductCount={(action) =>
              onChangeProductCount && onChangeProductCount(storeProductDisplay.id, action)
            }
            requestedQuantity={storeProductDisplay.cartData?.orderItem.requestedQuantity || 0}
            activeSellingUnit={storeProductDisplay.activeSellingUnit}
            productSellingUnits={
              outOfStockStoreProductById[storeProductDisplay.id]
                ? [storeProductDisplay.activeSellingUnit]
                : storeProductDisplay.productSellingUnits
            }
            onChooseUnit={(sellingUnitId) =>
              onChooseUnit && onChooseUnit(storeProductDisplay.id, sellingUnitId)
            }
          />
        </Box>

        <Box className={classes.quantityValue}>
          <Typography
            variant="body2"
            fontWeight="bold"
            color="textPrimary"
            fontSize={16}
            align="center"
          >
            {renderItemCount(storeProductDisplay.cartData?.orderItem.requestedQuantity || 0)}
          </Typography>
          <Typography color="grey" fontSize={13} align="center">
            {renderLanguageField(
              storeProductDisplay.activeSellingUnit.sellingUnit.multiLang,
              'name',
            )}
          </Typography>
        </Box>
      </Box>
    );
  }, [
    classes.quantityButton,
    classes.quantityValue,
    storeProductDisplay.cartData?.orderItem.requestedQuantity,
    storeProductDisplay.activeSellingUnit,
    storeProductDisplay.id,
    storeProductDisplay.productSellingUnits,
    outOfStockStoreProductById,
    renderLanguageField,
    onChangeProductCount,
    onChooseUnit,
  ]);

  const promotionTag = useMemo(() => {
    return (
      <Box>
        <SaleHorizontalTag
          className={classes.tag}
          label={storeProductDisplay.promotion ? 'tag.deal' : 'tag.sale'}
        />
      </Box>
    );
  }, [classes.tag, storeProductDisplay]);

  return (
    <Box
      onMouseOver={handleMouseEnter}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={classes.wrapper}
    >
      {storeProductDisplay.cartData?.orderItem.isRemoved && onDeleteItem && (
        <RemovedCartItemSection
          onKeepItemInCart={handleDeleteOrKeepItemInCart}
          onDeleteItem={onDeleteItem}
          storeProductId={storeProductDisplay.id}
        />
      )}
      <Box className={classes.main}>
        {/* Render Promoted Tag if promoted = true */}
        {(storeProductDisplay.promotion || storeProductDisplay.promoted) && promotionTag}
        <Box>
          <IconButton
            size="small"
            onClick={handleDeleteOrKeepItemInCart}
            classes={{ root: classes.deleteButtonRoot }}
          >
            <Icon name="icon-button-x" color="action" classes={{ root: classes.deleteIconRoot }} />
          </IconButton>
          {props.bagOfProducts && !!props.bagOfProductsJson?.maxNumOfReplacements && (
            <IconButton
              size="small"
              onClick={() => showEditBasketDialog(storeProductDisplay)}
              classes={{ root: classes.editBasket }}
            >
              <Icon name="icon-edit" color="action" classes={{ root: classes.editBasketIcon }} />
            </IconButton>
          )}
        </Box>
        <Button
          disableRipple
          onClick={() => handleProductDetailsPopup(storeProductDisplay.id)}
          className={classes.productDetailsButton}
        >
          <Box className={classes.imageWrapper}>
            {/* product image */}
            <CardMedia
              classes={{ root: classes.cardImage }}
              component="img"
              image={storeProductDisplay.imageUrl}
              alt={storeProductDisplay.fullName}
            />
          </Box>
          <Box className={classes.description}>
            <Typography
              fontSize={14}
              color="textPrimary"
              className={classes.descriptionText}
              isTranslate={false}
            >
              {storeProductDisplay.fullName}
            </Typography>
          </Box>
        </Button>
        <Box className={classes.productDetails}>
          <Box className={classes.quantity}>{renderQuantity}</Box>
          <Box className={classes.price}>{pricesSectionRendered}</Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CartItem;
