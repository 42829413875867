import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Collapse,
  Divider,
  IconButton,
} from '@material-ui/core';

import classNames from 'classnames';

import {
  TCartView,
  TOnViewPreviousOrder,
  useCart,
  useDialog,
  useFreeProducts,
  useMobile,
  usePreviousOrders,
  useProduct,
  useRenderPrice,
  useWebsiteDetails,
} from 'hooks';
import usePromotions from 'hooks/usePromotions';
import React, {
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';
import {
  authSelectors,
  LOGIN_DIALOG,
  orderSelectors,
  PREVIOUS_ORDER_ITEMS_DIALOG,
  uiStylesActions,
} from 'store';
import { getLang } from 'store/modules/config/selectors';
import { getOrderMembershipBenefits } from 'store/modules/orderDiscounts/selectors';
import { getHomePageUiStyles } from 'store/modules/uiStyles/selectors';
import { getUserSubCatalogId } from 'store/modules/user/selectors';
import ContinueToCart from 'ui/common/buttons/ContinueToCart';

import CTAButton from 'ui/common/buttons/CTAButton';
import CartUniqueItemsCount from 'ui/common/icons/CartUniqueItemsCount';
import Icon from 'ui/common/icons/Icon';
import { MainLayoutContext } from 'ui/common/layout/MainLayout';
import PreviousOrdersList from 'ui/common/PreviousOrdersList';
import Typography from 'ui/common/Typography';
import CartItem from 'ui/desktop/CartItem';
import { cardActionsHeight, cardMarginBottom, headerHeight, topBlockHeight } from './constants';

import useStyles from './styles';

import { ITopCart } from './types';

const TopCart: FC<ITopCart> = ({
  onExpanded,
  onContinueToCart,
  showingPromotionsBanners = false,
  showBigHeader,
}) => {
  const { loading, getPreviousOrders, previousOrders, previousOrdersById } = usePreviousOrders();
  const dispatch = useDispatch();
  const websiteDetails = useWebsiteDetails();
  const renderPrice = useRenderPrice();
  const { showDialog, hideDialog } = useDialog();
  const { incentivesPromotionsProgressBar, incentiveToDisplay } = usePromotions();
  const userSubCatalogId = useSelector(getUserSubCatalogId);
  const orderMembershipBenefits = useSelector(getOrderMembershipBenefits);
  const mainLayoutContext = useContext(MainLayoutContext);

  const { t } = useTranslation();

  const {
    uniqueItemCount,
    itemsEstimation,
    isEmpty,
    areAllItemsRemoved,
    handleEmptyCart,
    renderPromotions,
    renderMembershipBenefits,
    nonWeighablePromotionRibbon,
    onShareCart,
    freeProductsOrderDiscounts,
  } = useCart();

  const {
    handleDeleteStoreProduct,
    handleChangeStoreProductQuantity,
    handleChooseUnit,
    getOrderItemToDisplay,
  } = useProduct();

  const { renderFreeProducts } = useFreeProducts();
  const { isMobile } = useMobile();

  const isLoggedIn = useSelector(authSelectors.isLoggedIn);
  const orderItems = useSelector(orderSelectors.orderItems);
  const { hideIncentivePromotion } = useSelector(getHomePageUiStyles);

  const lang = useSelector(getLang);

  // need for maxHeight in mainRootContent (list of items)
  const [distanceToWindowTop, setDistanceToWindowTop] = useState<number>(0);
  const [maxHeight, setMaxHeight] = useState<number>(0);
  const [marginTop, setMarginTop] = useState<number>(0);
  const [oldHeightOfIncentive, setOldHeightOfIncentive] = useState<number>(0);

  // need to understand that we need to scroll to bottom
  const [prevUniqueItemCount, setPrevUniqueItemCount] = useState<number>(uniqueItemCount);

  const [expanded, setExpanded] = useState(mainLayoutContext.isOpenedTopCart);

  const [view, setView] = useState<TCartView>('default');

  const classes = useStyles({ expanded, view, isEmpty });

  const cardRef = useRef<null | HTMLElement>(null);
  const contentRef = useRef<null | HTMLElement>(null);
  const incentiveRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!isLoggedIn) setView('default');
  }, [isLoggedIn]);

  const handleGoBack = useCallback(() => {
    setView('default');
  }, []);

  const handleShowPreviousOrder = useCallback(() => {
    if (!isLoggedIn) {
      showDialog({
        dialogType: LOGIN_DIALOG,
      });
      return;
    }
    setView('previousOrder');
    getPreviousOrders();
  }, [getPreviousOrders, isLoggedIn, showDialog]);

  const handleViewPreviousOrder = useCallback<TOnViewPreviousOrder>(
    (order) => () => {
      showDialog({
        dialogType: PREVIOUS_ORDER_ITEMS_DIALOG,
        dialogProps: {
          hideCloseButton: isMobile,
          backdropClick: isMobile,
        },
        contentProps: {
          order,
          onSuccess: () => {
            hideDialog();
            setView('default');
          },
        },
      });
    },
    [hideDialog, isMobile, showDialog],
  );

  const handleHideIncentives = useCallback(() => {
    dispatch(
      uiStylesActions.homePageStylesUpdate({
        hideIncentivePromotion: true,
      }),
    );
  }, [dispatch]);

  const cartItems = useMemo<ReactNode>(() => {
    if (!orderItems.length) {
      return (
        <>
          {renderFreeProducts({ freeProductsDiscounts: freeProductsOrderDiscounts })}
          <Divider classes={{ root: classes.rootDivider }} />
          {renderPromotions(true, 0, 200)}
          {renderMembershipBenefits()}
        </>
      );
    }

    return (
      <Box>
        {orderItems.map(({ storeProduct }, index) => {
          const displayableStoreProduct = getOrderItemToDisplay(storeProduct);
          if (!displayableStoreProduct) {
            return;
          }

          return (
            <>
              <Box component="article" key={storeProduct.id}>
                <CartItem
                  {...displayableStoreProduct}
                  onDeleteItem={handleDeleteStoreProduct}
                  onChangeProductCount={handleChangeStoreProductQuantity}
                  onChooseUnit={handleChooseUnit}
                />
              </Box>
              {renderPromotions(false, index, 200) || (
                <Divider classes={{ root: classes.rootDivider }} />
              )}
              {nonWeighablePromotionRibbon(index) ? (
                <>
                  {nonWeighablePromotionRibbon(index)}
                  <Divider classes={{ root: classes.rootDivider }} />
                </>
              ) : (
                <Divider classes={{ root: classes.rootDivider }} />
              )}
            </>
          );
        })}
        {renderFreeProducts({ freeProductsDiscounts: freeProductsOrderDiscounts })}
        {renderPromotions(true, 0, 200) || <Divider classes={{ root: classes.rootDivider }} />}
        {renderMembershipBenefits()}
      </Box>
    );
  }, [
    orderItems,
    renderFreeProducts,
    freeProductsOrderDiscounts,
    renderPromotions,
    classes.rootDivider,
    renderMembershipBenefits,
    getOrderItemToDisplay,
    handleDeleteStoreProduct,
    handleChangeStoreProductQuantity,
    handleChooseUnit,
    nonWeighablePromotionRibbon,
  ]);

  const renderTopBlockButton = useMemo(() => {
    switch (true) {
      case view === 'previousOrder':
        return (
          <Box display="flex" justifyContent="flex-end" alignItems="center">
            <Button
              className={classes.topButtonsRoot}
              onClick={handleGoBack}
              style={{ display: 'flex', alignItems: 'end' }}
            >
              <Icon
                name="icon-arrow"
                classes={{ root: classNames(classes.icon, classes.backIcon) }}
                color="primary"
              />
              <Typography
                variant="body1"
                fontSize={14}
                color="textSecondary"
                className={classes.iconLabel}
              >
                {'topCard.backToCart'}
              </Typography>
            </Button>
          </Box>
        );
      // show load previous order if cart is empty and user logged in
      case view === 'default':
        return (
          <>
            {!isEmpty && !websiteDetails.store.hasOnlineBranches && !userSubCatalogId && (
              <Box className={classes.verticalDivider} />
            )}
            <Box display="flex" justifyContent="flex-end">
              <Button className={classes.topButtonsRoot} onClick={handleShowPreviousOrder}>
                <Icon name="icon-refresh" classes={{ root: classes.icon }} color="primary" />
                <Typography
                  variant="body1"
                  fontSize={14}
                  color="textSecondary"
                  className={classes.iconLabel}
                >
                  {'topCard.showPreviousOrder'}
                </Typography>
              </Button>
            </Box>
          </>
        );
      default:
        return null;
    }
  }, [
    view,
    classes.topButtonsRoot,
    classes.icon,
    classes.backIcon,
    classes.iconLabel,
    classes.verticalDivider,
    handleGoBack,
    isEmpty,
    websiteDetails.store.hasOnlineBranches,
    userSubCatalogId,
    handleShowPreviousOrder,
  ]);

  const topBlock = useMemo<ReactNode>(() => {
    return (
      <Box className={classes.topBlockRoot}>
        <Box className={classes.topBlockWrapper}>
          {view === 'default' &&
            !isEmpty &&
            !websiteDetails.store.hasOnlineBranches &&
            !userSubCatalogId && (
              <>
                <Box display="flex" justifyContent="flex-end">
                  <Button className={classes.topButtonsRoot} onClick={onShareCart}>
                    <Icon name="icon-share" classes={{ root: classes.icon }} color="primary" />
                    <Typography
                      variant="body1"
                      fontSize={14}
                      color="textSecondary"
                      className={classes.iconLabel}
                    >
                      {'topCard.shareCart'}
                    </Typography>
                  </Button>
                </Box>
              </>
            )}
          <>{renderTopBlockButton}</>
          {view === 'default' && !isEmpty && (
            <>
              {<Box className={classes.verticalDivider} />}
              <Button className={classes.topButtonsRoot} onClick={handleEmptyCart}>
                <Icon name="icon-trash" classes={{ root: classes.icon }} color="primary" />
                <Typography
                  variant="body1"
                  fontSize={14}
                  color="textSecondary"
                  className={classes.iconLabel}
                >
                  {'topCard.deleteBasket'}
                </Typography>
              </Button>
            </>
          )}
        </Box>
      </Box>
    );
  }, [
    classes.topBlockRoot,
    classes.topBlockWrapper,
    classes.topButtonsRoot,
    classes.icon,
    classes.iconLabel,
    classes.verticalDivider,
    view,
    isEmpty,
    websiteDetails.store.hasOnlineBranches,
    userSubCatalogId,
    onShareCart,
    renderTopBlockButton,
    handleEmptyCart,
  ]);

  const cartChildren = useMemo<ReactNode>(() => {
    switch (view) {
      case 'previousOrder':
        return (
          <PreviousOrdersList
            loading={loading}
            previousOrders={previousOrders}
            previousOrdersById={previousOrdersById}
            onViewPreviousOrder={handleViewPreviousOrder}
            onEditActiveOrderStart={handleGoBack}
          />
        );
      case 'default':
      default:
        return cartItems;
    }
  }, [
    view,
    loading,
    previousOrders,
    previousOrdersById,
    handleViewPreviousOrder,
    handleGoBack,
    cartItems,
  ]);

  const updateTopMargin = useCallback(() => {
    // update top margin
    const windowScrollY = window.scrollY;
    if (!showingPromotionsBanners) {
      setMarginTop(0);
    } else {
      const criticalScrollYPosition = showBigHeader ? 220 : 40;
      setMarginTop(
        windowScrollY > criticalScrollYPosition
          ? -(Math.min(30, windowScrollY - criticalScrollYPosition) / 8)
          : 0,
      );
    }
  }, [showBigHeader, showingPromotionsBanners]);

  const updateCartHeightAndTopMargin = useCallback(
    (newDistanceToWindowTop, subtractFromCartHeight, incentiveHeight) => {
      // top card block height, if not exist = 0
      const topBlockHeightValue = topBlock ? topBlockHeight : 0;

      const screenHeight =
        window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
      // card height without list of items height
      const cardStaticContentHeight = headerHeight + topBlockHeightValue + cardActionsHeight;

      // max height for list of items
      let newMaxHeight =
        screenHeight -
        newDistanceToWindowTop -
        cardStaticContentHeight -
        cardMarginBottom -
        incentiveHeight;

      // recalculate new max height only if cart height difference is negative.
      if (subtractFromCartHeight < 0) newMaxHeight += subtractFromCartHeight;

      setMaxHeight(newMaxHeight);

      updateTopMargin();
    },
    [topBlock, updateTopMargin],
  );

  const handleScroll = useCallback(() => {
    if (!expanded) {
      updateTopMargin();
      return;
    }

    // Required distance between cart bottom to the top of the footer or to the bottom of the viewport.
    const cartBottomMarginValue = 16;

    // distance from cart bottom to the top of the footer
    const footerTop = document.getElementsByTagName('footer')[0].getBoundingClientRect().top || 0;
    const cartBottom = cardRef.current?.getBoundingClientRect().bottom || 0;
    const cartToFooterDistance = footerTop - cartBottom;

    let newIncentiveHeight = 0;

    if (incentiveRef.current?.clientHeight) {
      newIncentiveHeight = incentiveRef.current?.clientHeight;
    }

    // distance from cart bottom to the bottom of the viewport
    const cartToScreenBottomDistance = window.innerHeight - cartBottom;

    const cartHeightDifference = cartToFooterDistance - cartToScreenBottomDistance;

    // distance from cart to top of the screen
    const newDistanceToWindowTop = cardRef.current?.getBoundingClientRect().top || 0;

    setDistanceToWindowTop((oldDistanceToWindowTop) => {
      if (
        newDistanceToWindowTop !== oldDistanceToWindowTop ||
        cartToFooterDistance < cartBottomMarginValue ||
        cartToScreenBottomDistance > cartBottomMarginValue ||
        oldHeightOfIncentive !== newIncentiveHeight
      ) {
        requestAnimationFrame(() =>
          updateCartHeightAndTopMargin(
            newDistanceToWindowTop,
            cartHeightDifference,
            newIncentiveHeight,
          ),
        );

        if (oldHeightOfIncentive !== newIncentiveHeight) {
          setOldHeightOfIncentive(newIncentiveHeight);
        }
        return newDistanceToWindowTop;
      }

      return oldDistanceToWindowTop;
    });
  }, [expanded, oldHeightOfIncentive, updateCartHeightAndTopMargin, updateTopMargin]);

  useEffect(() => {
    handleScroll();
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [distanceToWindowTop, handleScroll, updateCartHeightAndTopMargin]);

  useEffect(() => {
    setExpanded(mainLayoutContext.isOpenedTopCart);
  }, [mainLayoutContext.isOpenedTopCart]);

  // scroll to bottom
  useEffect(() => {
    const clientHeight = contentRef.current?.clientHeight || 0;
    const scrollHeight = contentRef.current?.scrollHeight || 0;

    // check if it is new item and if we have scroll height
    if (scrollHeight > clientHeight && prevUniqueItemCount < uniqueItemCount) {
      contentRef.current?.scrollTo(0, contentRef.current?.scrollHeight);
    }
    setPrevUniqueItemCount(uniqueItemCount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uniqueItemCount]);

  const handleExpandClick = useCallback(() => {
    setExpanded(!expanded);
    if (onExpanded) {
      onExpanded(!expanded);
    }
  }, [expanded, onExpanded]);

  const renderPriceBlock = useMemo<ReactNode>(() => {
    return (
      <Box ml={1}>
        <Typography
          className={classNames(classes.shoppingText, 'font-family-rubik')}
          color="light"
          variant="h2"
          fontSize={14}
        >
          {'topCard.myShoppingBag'}
        </Typography>
        <Typography
          className={classes.priceText}
          color="light"
          variant="body2"
          fontSize={18}
          fontWeight={lang === 'ru' ? 'semiBoldRoboto' : 'semiBold'}
          isTranslate={false}
          // need for show 3 dots on the right side
          dir="ltr"
        >
          {renderPrice(itemsEstimation)}
        </Typography>
      </Box>
    );
  }, [classes.priceText, classes.shoppingText, itemsEstimation, lang, renderPrice]);

  const renderEmptyContent = useMemo<ReactNode>(() => {
    return (
      <Box className={classes.rootEmpty}>
        <CardMedia
          src={websiteDetails.theme.images.emptyCart}
          component="img"
          className={classes.emptyBasketImage}
          alt={t('topCard.cardEmpty')}
        />

        <Typography
          className={classes.mainEmptyText}
          variant="h3"
          fontWeight="bold"
          color="secondary"
        >
          {'topCard.cardEmpty'}
        </Typography>
        <Typography variant="body1" fontSize={16} color="grey">
          {'topCard.startAdding'}
        </Typography>
      </Box>
    );
  }, [
    classes.emptyBasketImage,
    classes.mainEmptyText,
    classes.rootEmpty,
    websiteDetails.theme.images.emptyCart,
    t,
  ]);

  const disabled = useMemo(() => {
    return isEmpty || areAllItemsRemoved || !itemsEstimation;
  }, [itemsEstimation, isEmpty, areAllItemsRemoved]);

  return (
    <Box mt={marginTop}>
      <Card classes={{ root: classes.root }} innerRef={cardRef}>
        <CardContent
          classes={{
            root: classes.topHeader,
          }}
        >
          <Box display="flex" alignItems="center">
            <IconButton
              aria-label={t('topCard.expandCart')}
              aria-expanded={expanded}
              onClick={handleExpandClick}
              classes={{
                root: classNames(classes.expand, classes.expandButton, {
                  [classes.expandOpen]: expanded,
                }),
                label: classes.expandButtonLabel,
              }}
            >
              <Icon color="primary" classes={{ root: classes.expandIcon }} name="icon-arrow-down" />
            </IconButton>

            <Box className={classes.priceWithBasketBlock}>
              <CartUniqueItemsCount count={uniqueItemCount} />
              {renderPriceBlock}
            </Box>
          </Box>

          <CTAButton className={classes.rootButton} onClick={onContinueToCart} disabled={disabled}>
            <Box className={classes.buttonLabelWrp}>
              <Typography
                className={classes.buttonLabelText}
                variant="body1"
                fontWeight="medium"
                fontSize={16}
              >
                {'topCard.continue'}
              </Typography>
              <Typography
                className={classes.buttonLabelText}
                variant="body1"
                fontWeight="medium"
                fontSize={16}
              >
                {'topCard.payment'}
              </Typography>
            </Box>
          </CTAButton>
        </CardContent>

        <Collapse in={expanded} timeout="auto" unmountOnExit>
          {topBlock}
          {view === 'default' && !isEmpty && !hideIncentivePromotion && incentiveToDisplay && (
            <>
              <div className={classes.incentivesWrapper} ref={incentiveRef}>
                {incentivesPromotionsProgressBar(handleHideIncentives)}
              </div>
              <Divider classes={{ root: classes.incentiveDivider }} />
            </>
          )}
          <CardContent
            classes={{ root: classes.mainRootContent }}
            innerRef={contentRef}
            style={{ height: maxHeight }}
          >
            {isEmpty &&
            view === 'default' &&
            !freeProductsOrderDiscounts.length &&
            !orderMembershipBenefits?.budgetToUse &&
            (!orderMembershipBenefits?.requestedBenefits ||
              Object.keys(orderMembershipBenefits?.requestedBenefits).length === 0)
              ? renderEmptyContent
              : cartChildren}
          </CardContent>
          <CardActions disableSpacing classes={{ root: classes.cardActionsRoot }}>
            <Typography
              className={classes.bottomText}
              variant="body1"
              fontSize={14}
              color="mediumGrey"
            >
              {'topCard.revaluation'}
            </Typography>
            <ContinueToCart price={itemsEstimation} disabled={disabled} onClick={onContinueToCart}>
              {t('button.continueToCheckout')}
            </ContinueToCart>
          </CardActions>
        </Collapse>
      </Card>
    </Box>
  );
};

export default TopCart;
