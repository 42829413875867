import { createStyles, makeStyles, StyleRules } from '@material-ui/core';

import { TStylesBannerPrompt } from './types';

export default makeStyles(
  (theme): StyleRules<TStylesBannerPrompt> =>
    createStyles({
      installBtn: {
        zIndex: theme.zIndex.modal,
        padding: 0,
        position: 'fixed',
        bottom: 0,
        borderRadius: '0',
        height: '46px',
        width: '100vw',
        backgroundColor: `${theme.palette.secondary.main} !important`,
        boxShadow: '0 -3px 6px 0 rgba(0, 0, 0, 0.2)',
      },
      closeIcon: {
        position: 'absolute',
        right: 0,
        padding: theme.spacing(12 / 8),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      addIcon: {
        fontSize: 14,
        marginRight: theme.spacing(4 / 8),
      },
    }),
);
