import { Box, useTheme } from '@material-ui/core';
import CategoryBanner from 'components/common/CategoryBanner';
import CategoryNameAndSort from 'components/common/CategoryNameAndSort';

import { TProductAdditionSource, useCategoryMenu, useMobile, useWebsiteDetails } from 'hooks';
import useMapState from 'hooks/useMapState';
import React, {
  CSSProperties,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { useSelector } from 'react-redux';

import { AutoSizer, Grid, GridCellRenderer, Index, Size, WindowScroller } from 'react-virtualized';
import { ICategoryBanner, IStoreProductDisplay } from 'store';
import { getHomePageUiStyles } from 'store/modules/uiStyles/selectors';
import { TMixedType, TProductCategoryServer } from 'types';
import CTAButton from 'ui/common/buttons/CTAButton';

import { MainLayoutContext } from 'ui/common/layout/MainLayout';
import StoreProductCard from 'ui/common/StoreProductCard';
import Typography from 'ui/common/Typography';
import { getBannerForCell } from 'utils/helpers/categoryBanners';
import useStyles from './styles';
import {
  IStoreProductsVirtualizedList,
  TGetCardLimit,
  TGridCellContainer,
  TLoadMoreProducts,
} from './types';

export const desktopSmallerCardSize = 208;
export const desktopBiggerCardSize = 230;
const desktopGutterSize = 22;

// TODO move to common
const StoreProductsVirtualizedList: FC<IStoreProductsVirtualizedList> = ({
  storeProducts,
  categoryHeaders,
  onSubCategorySort,
}) => {
  const { storeProductCardView } = useSelector(getHomePageUiStyles);
  const mobileMaximalCardSize = storeProductCardView === 'single' ? 292 : 194;
  const mobileMinimalCardSize = 140;
  const mobileGutterSize = 12;

  const mainLayoutContext = useContext(MainLayoutContext);

  const { isMobile } = useMobile();

  const websiteDetails = useWebsiteDetails();
  const { activeMenuItem } = useCategoryMenu();
  const { direction } = useTheme();
  const classes = useStyles();
  const [containerWidth, setContainerWidth] = useState(0);
  const [columnCount, setColumnCount] = useState(4);
  const [cardWidth, setCardWidth] = useState(0);
  const [gutterSize, setGutterSize] = useState(isMobile ? mobileGutterSize : desktopGutterSize);
  const [limitsByCategory, setLimitsByCategory] = useMapState<
    TProductCategoryServer['id'],
    number
  >();
  const [subCategoriesSortOrders, setSubCategoriesSortOrders] = useMapState<TMixedType, number>();

  const gridRef = useRef<Grid>(null);

  const [selectedProductCardTooltipId, setSelectedProductCardTooltipId] =
    useState<TMixedType | null>(null);

  const getCardLimit = useCallback<TGetCardLimit>(
    (categoryId) => {
      return limitsByCategory.get(categoryId) || columnCount * 4;
    },
    [columnCount, limitsByCategory],
  );

  const insertCellIntoGridCells = useCallback(
    (gridCells: TGridCellContainer[], cell: TGridCellContainer, column: number) => {
      switch (cell.type) {
        case 'storeProduct':
        case 'empty':
        default:
          gridCells.push(cell);
          break;
        case 'categoryHeader':
          if (column !== 0) {
            for (let i = columnCount; i > column; i -= 1) {
              gridCells.push({
                type: 'empty',
                entity: null,
              });
            }
          }

          gridCells.push(cell);

          for (let i = 0; i < columnCount - 1; i += 1) {
            gridCells.push({
              type: 'empty',
              entity: null,
            });
          }
          break;
        case 'banner':
          gridCells.push(cell);
          if (storeProductCardView === 'multiple') {
            gridCells.push({
              type: 'empty',
              entity: null,
            });
          }
          break;
        case 'loadMoreButton':
          if (column !== 0) {
            for (let i = columnCount; i > column; i -= 1) {
              gridCells.push({
                type: 'empty',
                entity: null,
              });
            }
          }

          gridCells.push(cell);

          for (let i = 0; i < columnCount - 1; i += 1) {
            gridCells.push({
              type: 'empty',
              entity: null,
            });
          }
          break;
      }
    },
    [columnCount, storeProductCardView],
  );

  const gridCells = useMemo<TGridCellContainer[]>(() => {
    const result: TGridCellContainer[] = [];

    let currentCategoryId: TMixedType;
    let numberOfCardsInCurrentCategory = 0;

    storeProducts.forEach((storeProduct) => {
      const row = Math.floor(result.length / columnCount);
      const column = result.length % columnCount;

      if (categoryHeaders) {
        if (!currentCategoryId) {
          currentCategoryId = storeProduct.productCategory.id;

          insertCellIntoGridCells(
            result,
            {
              type: 'categoryHeader',
              entity: storeProduct.productCategory,
            },
            column,
          );
        }

        if (currentCategoryId !== storeProduct.productCategory.id) {
          currentCategoryId = storeProduct.productCategory.id;

          insertCellIntoGridCells(
            result,
            {
              type: 'categoryHeader',
              entity: storeProduct.productCategory,
            },
            column,
          );

          numberOfCardsInCurrentCategory = 0;
        }

        if (numberOfCardsInCurrentCategory === getCardLimit(storeProduct.productCategory.id)) {
          insertCellIntoGridCells(
            result,
            {
              type: 'loadMoreButton',
              entity: storeProduct.productCategory,
            },
            column,
          );
        }
      }

      const bannerForCell = getBannerForCell(
        websiteDetails.bannersByCategory,
        activeMenuItem?.id || 0,
        isMobile,
        mainLayoutContext.isOpenedTopCart,
        row,
        column,
      );

      if (bannerForCell) {
        const { mobileBannerPath, desktopBannerPath } = bannerForCell;
        // make sure banner exists for mobile or desktop
        if ((isMobile && mobileBannerPath) || (!isMobile && desktopBannerPath)) {
          insertCellIntoGridCells(
            result,
            {
              type: 'banner',
              entity: bannerForCell,
            },
            column,
          );
        }
      }

      if (
        !categoryHeaders ||
        numberOfCardsInCurrentCategory < getCardLimit(storeProduct.productCategory.id)
      ) {
        insertCellIntoGridCells(
          result,
          {
            type: 'storeProduct',
            entity: storeProduct,
          },
          column,
        );
      }

      numberOfCardsInCurrentCategory += 1;
    });
    return result;
  }, [
    storeProducts,
    columnCount,
    categoryHeaders,
    websiteDetails.bannersByCategory,
    activeMenuItem?.id,
    isMobile,
    mainLayoutContext.isOpenedTopCart,
    getCardLimit,
    insertCellIntoGridCells,
  ]);

  const productAdditionSource = useMemo<TProductAdditionSource>(() => {
    const mobileOrDesktop = isMobile ? 'Mobile' : 'Desktop';

    if (mainLayoutContext.searchContext.searchQuery) {
      return `searchResults${mobileOrDesktop}`;
    }

    if (activeMenuItem?.id === -1) {
      return `mainPage${mobileOrDesktop}`;
    }

    if (activeMenuItem?.id === 0) {
      return `promotionsCategory${mobileOrDesktop}`;
    }

    return `categoryPage${mobileOrDesktop}`;
  }, [activeMenuItem?.id, isMobile, mainLayoutContext.searchContext.searchQuery]);

  useEffect(() => {
    if (containerWidth === 0) return;
    let newColumnCount;

    if (!isMobile) {
      if (mainLayoutContext.isOpenedTopCart) {
        newColumnCount = Math.floor(containerWidth / desktopSmallerCardSize);
        setCardWidth(desktopSmallerCardSize);
      } else {
        newColumnCount = Math.floor(containerWidth / desktopBiggerCardSize);
        setCardWidth(desktopBiggerCardSize);
      }

      setGutterSize(desktopGutterSize);
    } else {
      newColumnCount = Math.max(
        Math.min(
          Math.floor(containerWidth / mobileMinimalCardSize),
          Math.floor(containerWidth / mobileMaximalCardSize),
        ),
        storeProductCardView === 'single' ? 1 : 2,
      );

      const optimalCardWidth =
        (containerWidth - mobileGutterSize * (newColumnCount - 1)) / newColumnCount;

      setCardWidth(optimalCardWidth);
      setGutterSize(mobileGutterSize);
    }

    setColumnCount(newColumnCount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerWidth, storeProductCardView]); // we use only containerWidth on purpose for performance reasons

  useEffect(() => {
    setSubCategoriesSortOrders(new Map());
  }, [activeMenuItem, setSubCategoriesSortOrders, mainLayoutContext.searchContext.searchQuery]);

  const handleResize = useCallback((info: Size) => {
    setContainerWidth(info.width);
  }, []);

  const overScanIndicesGetter = useCallback(
    ({ cellCount, overscanCellsCount, startIndex, stopIndex }) => {
      return {
        overscanStartIndex: Math.max(0, startIndex - Math.floor(overscanCellsCount / 2)),
        overscanStopIndex: Math.min(cellCount - 1, stopIndex + Math.floor(overscanCellsCount / 2)),
      };
    },
    [],
  );

  const loadMoreProducts = useCallback<TLoadMoreProducts>(
    (categoryId) => {
      const currentLimit = limitsByCategory.get(categoryId) || getCardLimit(categoryId);

      setLimitsByCategory(limitsByCategory.set(categoryId, currentLimit + columnCount * 4));

      if (gridRef.current) {
        gridRef.current.recomputeGridSize();
      }
    },
    [columnCount, getCardLimit, limitsByCategory, setLimitsByCategory],
  );

  const renderStoreProduct = useCallback(
    (
      storeProduct: IStoreProductDisplay,
      _key: string,
      columnIndex: number,
      style: CSSProperties,
    ) => {
      const newStyles = { ...style };
      const mobileProps = isMobile && {
        isTooltipOpen: selectedProductCardTooltipId === storeProduct.id,
        onProductTooltipClick: (productId: TMixedType) =>
          setSelectedProductCardTooltipId(
            selectedProductCardTooltipId === productId ? null : productId,
          ),
        viewMode: storeProductCardView,
      };

      if (direction === 'rtl') {
        delete newStyles.left;
        newStyles.right = style?.left;
      }

      newStyles.width = cardWidth + (columnIndex !== columnCount - 1 ? gutterSize : 0);

      // TODO - check if not needed to urlEncode link
      return (
        <Box
          component="article"
          key={storeProduct.id}
          dir={direction}
          style={newStyles}
          className={classes.cardWrp}
          role="listitem"
        >
          <StoreProductCard
            storeProductDisplay={storeProduct}
            cardWidth={cardWidth}
            sourceEvent={productAdditionSource}
            {...mobileProps}
          />
        </Box>
      );
    },
    [
      storeProductCardView,
      cardWidth,
      classes.cardWrp,
      columnCount,
      direction,
      gutterSize,
      isMobile,
      productAdditionSource,
      selectedProductCardTooltipId,
    ],
  );

  const renderBanner = useCallback(
    (banner: ICategoryBanner, key: string, columnIndex: number, style: CSSProperties) => {
      const newStyles = { ...style };

      if (direction === 'rtl') {
        delete newStyles.left;
        newStyles.right = style?.left;
      }

      newStyles.width = cardWidth;

      if (storeProductCardView === 'multiple') {
        newStyles.width *= 2;
        if (columnIndex !== columnCount - 1) {
          newStyles.width += gutterSize;
        }
      }

      return (
        <div key={key} dir={direction} style={newStyles} className={classes.cardWrp}>
          <CategoryBanner gutter={gutterSize} {...banner} />
        </div>
      );
    },
    [cardWidth, classes.cardWrp, columnCount, direction, gutterSize, storeProductCardView],
  );

  const renderCategoryHeader = useCallback(
    (category: TProductCategoryServer, key: string, _columnIndex: number, style: CSSProperties) => {
      if (mainLayoutContext.searchContext.searchQuery) return;

      const newStyles = { ...style };

      if (direction === 'rtl') {
        delete newStyles.left;
        newStyles.right = style?.left;
        newStyles.height = 'auto';
        // key === '0-0' because of different styles for first children category
        if (key === '0-0') {
          newStyles.marginTop = isMobile ? '7px' : '22px';
        } else {
          newStyles.marginTop = isMobile ? '24px' : '40px';
        }
      }

      newStyles.width = columnCount * cardWidth + gutterSize * (columnCount - 1);

      if (!subCategoriesSortOrders.get(category.id)) {
        setSubCategoriesSortOrders(subCategoriesSortOrders.set(category.id, 9));
      }

      return (
        <Box key={key} dir={direction} style={newStyles} className={classes.cardWrp}>
          <CategoryNameAndSort
            categoryName={category.name}
            variant="child"
            selectedSortOrder={subCategoriesSortOrders.get(category.id) as number} // we know because we set it a few lines up
            sortOptions="in-category"
            onSortOrderChange={(newSortOrder) => {
              setSubCategoriesSortOrders(subCategoriesSortOrders.set(category.id, newSortOrder));
              onSubCategorySort(newSortOrder, category);
            }}
          />
        </Box>
      );
    },
    [
      cardWidth,
      classes.cardWrp,
      columnCount,
      direction,
      gutterSize,
      isMobile,
      mainLayoutContext.searchContext.searchQuery,
      onSubCategorySort,
      setSubCategoriesSortOrders,
      subCategoriesSortOrders,
    ],
  );

  const renderLoadMoreButton = useCallback(
    (category: TProductCategoryServer, key: string, _columnIndex: number, style: CSSProperties) => {
      const newStyles = { ...style };

      if (direction === 'rtl') {
        delete newStyles.left;
        newStyles.right = style?.left;
        newStyles.height = 'auto';
        newStyles.marginTop = isMobile ? '10px' : 0;
      }
      newStyles.width = columnCount * cardWidth + gutterSize * (columnCount - 1);
      newStyles.justifyContent = 'center';

      return (
        <Box key={key} dir={direction} style={newStyles} className={classes.cardWrp}>
          <CTAButton
            onClick={() => loadMoreProducts(category.id)}
            className={classes.loadMoreButton}
            size="medium"
            variant="outlined"
          >
            <Typography className={classes.loadMoreButtonLabel}>
              {'button.loadMoreProducts'}
            </Typography>
          </CTAButton>
        </Box>
      );
    },
    [
      cardWidth,
      classes.cardWrp,
      classes.loadMoreButton,
      classes.loadMoreButtonLabel,
      columnCount,
      direction,
      gutterSize,
      isMobile,
      loadMoreProducts,
    ],
  );

  const cellRenderer = useCallback<GridCellRenderer>(
    ({ columnIndex, key, rowIndex, style }) => {
      const cellItem = gridCells[rowIndex * columnCount + columnIndex];

      if (!cellItem || cellItem.type === 'empty') {
        return null;
      }

      if (cellItem.type === 'categoryHeader') {
        return renderCategoryHeader(
          cellItem.entity as TProductCategoryServer,
          key,
          columnIndex,
          style,
        );
      }

      if (cellItem.type === 'banner') {
        return renderBanner(cellItem.entity as ICategoryBanner, key, columnIndex, style);
      }

      if (cellItem.type === 'loadMoreButton') {
        return renderLoadMoreButton(
          cellItem.entity as TProductCategoryServer,
          key,
          columnIndex,
          style,
        );
      }

      return renderStoreProduct(cellItem.entity as IStoreProductDisplay, key, columnIndex, style);
    },
    [
      gridCells,
      columnCount,
      renderStoreProduct,
      renderCategoryHeader,
      renderBanner,
      renderLoadMoreButton,
    ],
  );

  const rowHeight = useCallback<(params: Index) => number>(
    ({ index }) => {
      const firstRowCell = gridCells[index * columnCount];
      if (firstRowCell?.type === 'categoryHeader') {
        if (index === 0) {
          return isMobile ? 55 : 84;
        }
        return isMobile ? 72 : 102;
      }

      if (firstRowCell?.type === 'loadMoreButton') {
        return isMobile ? 58 : 40;
      }
      return (isMobile ? 308 : 300) + gutterSize;
    },
    [columnCount, gridCells, gutterSize, isMobile],
  );

  return (
    <WindowScroller scrollElement={window}>
      {({ height, isScrolling, scrollTop }) => (
        <div style={{ direction: 'ltr' }}>
          {/* ltr hack for autosizer */}
          <AutoSizer onResize={handleResize} disableHeight>
            {({ width }) => (
              <Grid
                ref={gridRef}
                style={{ direction, overflowX: 'hidden', outline: 0, paddingTop: '6px' }}
                containerStyle={{ overflow: 'visible' }}
                containerRole="list"
                cellRenderer={cellRenderer}
                columnCount={columnCount}
                columnWidth={cardWidth + gutterSize}
                rowCount={Math.ceil(gridCells.length / columnCount)}
                rowHeight={rowHeight}
                estimatedRowSize={(isMobile ? 308 : 300) + gutterSize}
                width={width}
                height={height}
                autoHeight={true}
                isScrolling={isScrolling}
                scrollTop={scrollTop}
                overscanRowCount={6}
                overscanIndicesGetter={overScanIndicesGetter}
                role={null as unknown as undefined}
                aria-label={null as unknown as undefined}
                tabIndex={null as unknown as undefined}
                aria-readonly={null as unknown as undefined}
              />
            )}
          </AutoSizer>
        </div>
      )}
    </WindowScroller>
  );
};

export default StoreProductsVirtualizedList;
