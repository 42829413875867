import React, { FC, MouseEventHandler, useCallback } from 'react';

import { Button, IconButton } from '@material-ui/core';

import Typography from 'ui/common/Typography';
import Icon from 'ui/common/icons/Icon';

import useStyles from './styles';

import { ILoadPreviousOrder } from './types';

const LoadPreviousOrder: FC<ILoadPreviousOrder> = ({ onClick, onClose, ...rest }) => {
  const classes = useStyles();

  const handleClose = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (e) => {
      onClose();
      // avoid main click
      e.stopPropagation();
    },
    [onClose],
  );

  return (
    <Button
      classes={{ root: classes.root }}
      variant="contained"
      onClick={onClick}
      color="primary"
      fullWidth
      {...rest}
    >
      <Icon color="action" classes={{ root: classes.refreshIcon }} name="icon-refresh" />
      <Typography
        color="light"
        variant="body1"
        fontSize={16}
        fontWeight="medium"
        className="white-space-no-wrap text-transform-initial"
      >
        {'button.loadPreviousOrder'}
      </Typography>

      <IconButton className={classes.closeIconRoot} onClick={handleClose} aria-label="delete">
        <Icon name="icon-button-x" color="action" classes={{ root: classes.closeIcon }} />
      </IconButton>
    </Button>
  );
};

export default LoadPreviousOrder;
