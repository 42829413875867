import { Box, IconButton, useTheme } from '@material-ui/core';
import React, { FC, useCallback, useMemo, useRef, useState } from 'react';

import { Autoplay, EffectFade, Pagination } from 'swiper/modules';
import SwiperClass from 'swiper/types/swiper-class';

import { Swiper, SwiperSlide } from 'swiper/react';

import Icon from 'ui/common/icons/Icon';

import { uiStylesActions } from 'store';
import { useDispatch } from 'react-redux';
import { useMobile } from 'hooks';
import { IPromotion } from 'store/modules/promotions';
import { PaginationOptions } from 'swiper/types/modules/pagination';

import PromotionSlide, { TOnTooltipClick } from './PromotionSlide';
import useStyles from './styles';
import { IPromotionsBanner } from './types';

const PromotionsBanner: FC<IPromotionsBanner> = ({ promotions }) => {
  const theme = useTheme();

  const dispatch = useDispatch();

  const { isMobile } = useMobile();

  const classes = useStyles();

  const swiper = useRef<SwiperClass | null>(null);

  const [selectedPromotionTooltipId, setSelectedPromotionTooltipId] = useState<
    IPromotion['nonObfuscatedId'] | null
  >(null);

  const pagination = useMemo<PaginationOptions>(() => {
    return {
      clickable: true,
      renderBullet(_, className) {
        return `<a class="${className}"></a>`;
      },
      bulletClass: classes.pagination,
      bulletActiveClass: classes.activeBulletPagination,
      clickableClass: classes.clickablePagination,
    };
  }, [classes.activeBulletPagination, classes.clickablePagination, classes.pagination]);

  const goNext = useCallback(() => {
    swiper.current?.slideNext();
  }, []);

  const goPrev = useCallback(() => {
    swiper.current?.slidePrev();
  }, []);

  const resumeAutoPlay = useCallback(() => {
    if (swiper.current?.autoplay.paused) {
      swiper.current?.autoplay.resume();
    }
  }, []);

  const onHidePromotionsBanner = useCallback(() => {
    dispatch(
      uiStylesActions.homePageStylesUpdate({
        hidePromotionsBanner: true,
      }),
    );
  }, [dispatch]);

  const onTooltipClick = useCallback<TOnTooltipClick>(
    (promotionId) => {
      if (selectedPromotionTooltipId === promotionId) {
        // on mobile when click on the same tooltip close it and resume autoplay
        resumeAutoPlay();
      }
      setSelectedPromotionTooltipId((oldValue) => (oldValue === promotionId ? null : promotionId));
    },
    [resumeAutoPlay, selectedPromotionTooltipId],
  );

  const promotionSwiper = useMemo(() => {
    return (
      <Swiper
        modules={[EffectFade, Pagination, Autoplay]}
        onInit={(element: SwiperClass) => {
          swiper.current = element;
        }}
        onSlideChange={() => {
          resumeAutoPlay();
          setSelectedPromotionTooltipId(null);
        }}
        loop
        effect="fade"
        fadeEffect={{ crossFade: true }}
        speed={800}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        loopPreventsSlide={false}
        slidesPerView={1}
        pagination={isMobile && promotions.length > 1 && pagination}
        dir={theme.direction}
        key={theme.direction}
        className={classes.swiper}
      >
        {promotions.map((promotion, index) => {
          return (
            <SwiperSlide
              dir={theme.direction}
              key={index}
              onMouseOver={() => {
                swiper.current?.autoplay.pause();
              }}
              onMouseLeave={resumeAutoPlay}
            >
              <PromotionSlide
                promotion={promotion}
                showTooltip={selectedPromotionTooltipId === promotion.nonObfuscatedId}
                onTooltipClick={onTooltipClick}
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
    );
  }, [
    classes.swiper,
    isMobile,
    onTooltipClick,
    pagination,
    promotions,
    resumeAutoPlay,
    selectedPromotionTooltipId,
    theme.direction,
  ]);

  return (
    <Box>
      <Box className={classes.promotionBannerWrapper}>
        <Box className={classes.promotionBannerContainer}>
          <Box className={classes.sliderContainer}>
            {promotions.length > 1 && (
              <IconButton classes={{ root: classes.navigationButton }} onClick={goPrev}>
                <Icon
                  color="primary"
                  name={theme.direction === 'rtl' ? 'icon-arrow-right' : 'icon-arrow-left'}
                  classes={{ root: classes.navigationIcon }}
                />
              </IconButton>
            )}
            {promotionSwiper}
            {promotions.length > 1 && (
              <IconButton classes={{ root: classes.navigationButton }} onClick={goNext}>
                <Icon
                  color="primary"
                  name={theme.direction === 'rtl' ? 'icon-arrow-left' : 'icon-arrow-right'}
                  classes={{ root: classes.navigationIcon }}
                />
              </IconButton>
            )}
          </Box>
          <IconButton aria-label="close" className="padding-0" onClick={onHidePromotionsBanner}>
            <Icon
              name="icon-button-x"
              color="primary"
              classes={{ root: classes.hidePromotionBanner }}
            />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export default PromotionsBanner;
