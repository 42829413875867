import React, { FC } from 'react';
import { ICategoryBannerComponent } from 'components/common/CategoryBanner/types';
import { CardMedia } from '@material-ui/core';
import { useMobile } from 'hooks';
import useStyles from './styles';

const CategoryBanner: FC<ICategoryBannerComponent> = ({
  gutter,
  desktopBannerPath,
  mobileBannerPath,
}) => {
  const classes = useStyles({ gutter });
  const { isMobile } = useMobile();

  return (
    <div className={classes.wrapper}>
      <CardMedia
        alt="Category Banner"
        classes={{ root: classes.image }}
        component="img"
        image={`${process.env.NEXT_PUBLIC_ROOT_IMAGE}/${
          isMobile ? mobileBannerPath : desktopBannerPath
        }`}
      />
    </div>
  );
};

export default CategoryBanner;
