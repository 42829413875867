import { Box } from '@material-ui/core';
import useHomePageContent from 'hooks/useHomePageContent';
import React, { FC, useMemo } from 'react';
import { IHomePageContent } from './types';

const HomePageContent: FC<IHomePageContent> = ({ homePageContent }) => {
  const { renderHomePageComponents } = useHomePageContent();
  const pageContent = useMemo(() => {
    if (!homePageContent?.length) return;

    return <Box>{renderHomePageComponents(homePageContent)}</Box>;
  }, [homePageContent, renderHomePageComponents]);
  return <Box>{pageContent}</Box>;
};
export default HomePageContent;
