import { TUseMapState } from 'hooks/useMapState/types';
import { useCallback, useState } from 'react';

const useMapState: TUseMapState = <K, V>(initialState?: Map<K, V>) => {
  const [map, setMap] = useState<Map<K, V>>(initialState || new Map<K, V>());

  const setState = useCallback((newState: Map<K, V>) => {
    setMap(new Map(newState));
  }, []);

  return [map, setState];
};

export default useMapState;
