import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';

import { IContinueToCart, TStylesContinueToCart } from './types';

const downBreakpoint: Breakpoint = 'sm';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesContinueToCart, IContinueToCart> =>
    createStyles({
      rootButton: {
        padding: 0,
        height: theme.spacing(5.75), // 46px
        display: 'flex',
        justifyContent: 'space-between',
        overflow: 'hidden',
        width: theme.spacing(36), // 288px
        borderRadius: theme.spacing(0.75), // 6px
        boxShadow: 'none',

        '&:hover': {
          boxShadow: 'none',
        },

        '&:active': {
          backgroundColor: theme.palette.primary.dark,
          boxShadow: 'none',
        },

        '&:disabled': {
          backgroundColor: theme.palette.primary.main,
          opacity: 0.4,

          [theme.breakpoints.down(downBreakpoint)]: {
            opacity: 0.3,
          },
        },
        [theme.breakpoints.down(downBreakpoint)]: {
          width: '100%',
          borderRadius: theme.spacing(12.5), // 100px
        },
      },
      buttonRipple: {
        backgroundColor: '#000 !important',
        opacity: '0.3 !important',
      },
      price: {
        backgroundColor: theme.palette.primary.dark,
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(2),
        minWidth: theme.spacing(13), // 104px
        [theme.breakpoints.down(downBreakpoint)]: {
          minWidth: theme.spacing(14.375), // 115px
        },
      },
      label: {
        height: '100%',
      },
    }),
);
