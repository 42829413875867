import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';

import { TStylesLoadPreviousOrder } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesLoadPreviousOrder> =>
    createStyles({
      root: {
        height: theme.spacing(5), // 40px
        position: 'relative',
        boxShadow: '0 -3px 7px 0 rgba(0, 0, 0, 0.12)',
        borderRadius: 'initial',

        '&:hover': {
          backgroundColor: theme.palette.primary.main,
          boxShadow: 'none',
        },

        '&:active': {
          backgroundColor: theme.palette.primary.dark,
          boxShadow: 'none',
        },
      },
      closeIconRoot: {
        flip: false as unknown as string,
        position: 'absolute',
        right: 2,
        color: theme.palette.background.paper,
        padding: theme.spacing(1.5), // 12px
        display: 'flex',
        alignItems: 'center',
        minWidth: 'unset',
      },
      closeIcon: {
        fontSize: theme.spacing(1.75), // 14px
      },
      refreshIcon: {
        fontSize: theme.spacing(2.25), // 18px,
        marginRight: theme.spacing(0.75), // 6px
      },
    }),
);
