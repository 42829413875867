import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';

import { TStylesCardList } from './types';

export default makeStyles(
  (
    theme: Theme,
  ): StyleRules<
    TStylesCardList,
    {
      isOpenedTopCart?: boolean;
      hidePromotionsBanner: boolean;
      promotionsForBannersLength?: number;
    }
  > =>
    createStyles({
      rootWrp: ({ isOpenedTopCart }) => ({
        marginBottom: theme.spacing(2.5), // 20px
        paddingTop: 0,
        maxWidth: isOpenedTopCart ? 898 : '100%',
        [theme.breakpoints.up('md')]: {
          paddingTop: ({ hidePromotionsBanner, promotionsForBannersLength }) =>
            hidePromotionsBanner || !promotionsForBannersLength ? '34px' : '18px',
        },
      }),
      productListWrapper: ({ isOpenedTopCart }) => ({
        marginBottom: theme.spacing(2.5), // 20px
        maxWidth: isOpenedTopCart ? 898 : '100%',
        [theme.breakpoints.up('md')]: {
          marginBottom: theme.spacing(12.5), // 100px
        },
      }),
      subCategoryLink: {
        color: theme.palette.primary.main,
        fontSize: '16px',
        lineHeight: '20px',
        fontWeight: 500,
      },
      iconArrowRight: {
        fontSize: '12px',
        marginRight: theme.spacing(6 / 8),
      },
      iconArrowLeft: {
        fontSize: '12px',
        marginLeft: theme.spacing(6 / 8),
      },
      link: {
        marginTop: theme.spacing(12 / 8),
        display: 'flex',
        alignItems: 'center',
      },
    }),
);
