import { Box } from '@material-ui/core';
import { OrderService, SubscriptionService } from 'api';
import { OrderDraftsService } from 'api/services/OrderDraftsService';
import ShareCartService from 'api/services/ShareCartService/api';
import HomePageContent from 'components/common/HomePageContent';
import PromotionsBanner from 'components/common/PromotionsBanner';
import StoreProductsWrapper from 'components/common/StoreProductsWrapper';
import ContinueToPaymentFixed from 'components/mobile/ContinueToPaymentFixed';
import LoadPreviousOrderFixed from 'components/mobile/LoadPreviousOrderFixed';
import PWABannerPrompt from 'components/mobile/PWABannerPrompt';

import {
  useActiveOrdersAndSubscriptionsChecks,
  useCart,
  useCategoryMenu,
  useDialog,
  useMobile,
} from 'hooks';
import useHomePageContent from 'hooks/useHomePageContent';
import { useOrderDrafts } from 'hooks/useOrderDrafts';
import usePWAInstallerPrompt from 'hooks/usePWAInstallerPrompt';
import { useRouter } from 'next/router';
import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  CART_WAS_SHARED_DIALOG,
  LOGIN_DIALOG,
  orderActions,
  orderSelectors,
  promotionsSelectors,
  storeProductSelectors,
  websiteActions,
} from 'store';
import { authSelectors } from 'store/modules/auth';
import { checkAndFetchBranchCatalogIfNecessary } from 'store/modules/catalog/actions';
import { getCorrectBranchSubCatalogWasFetched } from 'store/modules/catalog/selectors';
import { getFullCatalogLoaded } from 'store/modules/config/selectors';
import { WELCOME_DIALOG } from 'store/modules/dialog';
import { getOrderDetails, getOrderMode } from 'store/modules/orderDetails/selectors';
import { validateAndEditOrder } from 'store/modules/orderEditing/actions';
import { IPromotion } from 'store/modules/promotions';
import { introductoryPopupsCompletedAction } from 'store/modules/shoppingFlow/actions';
import { startEditSubscription } from 'store/modules/subscriptions/actions';
import { getHomePageUiStyles } from 'store/modules/uiStyles/selectors';
import { getDetailsAtStoreLevel } from 'store/modules/user/selectors';
import { getWebsiteDetails } from 'store/modules/website/selectors';
import { TOrderItemDTO } from 'types';

import Container from 'ui/common/Container';

import { MainLayoutContext } from 'ui/common/layout/MainLayout';
import CategoryMenu from 'ui/desktop/CategoryMenu';
import TopCart, { TOnExpanded } from 'ui/desktop/TopCart';
import MobileCategoryMenu from 'ui/mobile/MobileCategoryMenu';
import { filterItemsOutOfStock } from 'utils/helpers/order/items';

import useStyles from './styles';
import { IMainPagesWrapper } from './types';

const MainPagesWrapper: FC<IMainPagesWrapper> = ({
  welcomeDialogContent,
  isFirstRender = false,
  isShowHomePage = false,
  homePageContent,
}) => {
  const { homePageId } = useHomePageContent();

  const { isMobile } = useMobile();

  const orderMode = useSelector(getOrderMode);
  const order = useSelector(orderSelectors.orderData);
  const fullCatalogLoaded = useSelector(getFullCatalogLoaded);
  const onClientBehalf = useSelector(authSelectors.onClientBehalf);
  const { storeProductById, ancestorStoreProductById } = useSelector(
    storeProductSelectors.storeProductsData,
  );
  const isLoggedIn = useSelector(authSelectors.isLoggedIn);
  const websiteDetails = useSelector(getWebsiteDetails);
  const detailsAtStoreLevel = useSelector(getDetailsAtStoreLevel);
  const { hidePromotionsBanner } = useSelector(getHomePageUiStyles);
  const { promotionsForBanners } = useSelector(promotionsSelectors.promotionsData);
  const orderDetails = useSelector(getOrderDetails);
  const correctBranchSubCatalogWasFetched = useSelector(getCorrectBranchSubCatalogWasFetched);

  const router = useRouter();

  const { handleOnInstall, handleOnDismiss } = usePWAInstallerPrompt();

  const dispatch = useDispatch();

  const { showDialog } = useDialog();

  const { activeMenuItem, menuItems, menuItemsFlattened } = useCategoryMenu();

  const { checkActiveOrdersAndSubscriptions } = useActiveOrdersAndSubscriptionsChecks();

  const { handleContinueToCart, isEmpty, uniqueItemCount } = useCart();

  const mainLayoutContext = useContext(MainLayoutContext);

  const [promotionsForBannersToDisplay, setPromotionsForBannersToDisplay] = useState<IPromotion[]>(
    [],
  );

  useOrderDrafts();

  const classes = useStyles({
    isMobile,
    orderMode,
    hidePromotionsBanner,
    promotionsForBannersLength: promotionsForBanners.length,
    isShowHomePage,
  });

  const handleOnExpandTopCart = useCallback<TOnExpanded>(
    (expanded) => {
      mainLayoutContext.setUserTopCartChoice(expanded ? 'open' : 'close');
      mainLayoutContext.setIsOpenedTopCart(expanded);
    },
    [mainLayoutContext],
  );

  const showWelcomeDialog = useCallback(
    (onWelcomeDialogContentAbsentCallback?: () => void) => {
      if (welcomeDialogContent && welcomeDialogContent.length && websiteDetails.showWelcomeDialog) {
        showDialog({
          dialogType: WELCOME_DIALOG,
        });

        dispatch(
          websiteActions.getWebsiteSuccess({
            websiteDetails: {
              ...websiteDetails,
              lastTimeShowingWelcomeDialog: new Date().getTime(),
            },
          }),
        );
        return;
      }

      if (onWelcomeDialogContentAbsentCallback) {
        onWelcomeDialogContentAbsentCallback();
      }
    },
    [dispatch, showDialog, websiteDetails, welcomeDialogContent],
  );

  const mainPageContent = useMemo(() => {
    return homePageContent?.length ? (
      <HomePageContent homePageContent={homePageContent} />
    ) : (
      <Container maxContainerWidth fixed>
        <Box>
          <StoreProductsWrapper />
        </Box>
      </Container>
    );
  }, [homePageContent]);

  useEffect(() => {
    if (
      fullCatalogLoaded &&
      isLoggedIn &&
      !onClientBehalf &&
      !router.query.share_cart &&
      orderMode === 'new' &&
      correctBranchSubCatalogWasFetched
    ) {
      OrderDraftsService.getOrderDraft().then((actionResultDrafts) => {
        if (
          actionResultDrafts.data &&
          (orderDetails.lastActivityTime || 0) < actionResultDrafts.data.updateDate
        ) {
          const itemsFiltered = filterItemsOutOfStock(
            JSON.parse(actionResultDrafts.data.content),
            storeProductById,
            ancestorStoreProductById,
          );
          if (!itemsFiltered.itemsInStock.length) return;

          const orderItems = itemsFiltered.itemsInStock.map((item) => ({
            ...item,
            loadedFrom: 'drafts',
          }));

          dispatch(orderActions.setOrderItemsSuccessAction(orderItems as TOrderItemDTO[]));
        }
      });
    }
    // we want to run this effect only once - when user is login
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullCatalogLoaded, isLoggedIn, correctBranchSubCatalogWasFetched]);

  // main useEffect that deals with items to be put in the cart, after the one that runs in AppContainer (order draft / shared cart / edited order)
  useEffect(() => {
    if (fullCatalogLoaded) {
      if (orderMode === 'new' && !onClientBehalf && router.query.share_cart) {
        ShareCartService.getSharedCart(router.query.share_cart).then((actionResult) => {
          if (actionResult.data) {
            const sharedCartFilteredItems = filterItemsOutOfStock(
              JSON.parse(actionResult.data.content),
              storeProductById,
            );

            if (sharedCartFilteredItems.itemsInStock.length) {
              const itemsFromSharedCart = sharedCartFilteredItems.itemsInStock.map((item) => {
                return {
                  ...item,
                  sourceEvent: 'sharedCart',
                  selectedBagItems:
                    typeof item.selectedBagItems === 'string'
                      ? JSON.parse(item.selectedBagItems)
                      : {},
                };
              });

              dispatch(
                orderActions.setOrderItemsSuccessAction(itemsFromSharedCart as TOrderItemDTO[]),
              );
              showDialog({
                dialogType: CART_WAS_SHARED_DIALOG,
              });
            }
          }
        });

        router.replace('/');
      }
    }
    // we are not running this effect on subsequent changes to any state variable except "fullCatalogLoaded" (this is intentional, we want just the initial state)
    // TODO replace this code when moving to an approach of keeping storeProduct data in cart items
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullCatalogLoaded]);

  // TODO check to move to AppContainer (if someone refreshes page on edit order mode in a cart page or a non shopping page we also want to bring latest data) - low priority
  useEffect(() => {
    // re-validate order editing after refresh
    if (!fullCatalogLoaded) return;
    if (isFirstRender && isLoggedIn && order.id) {
      if (orderMode === 'edit') {
        OrderService.getOrder(order.id).then((actionResult) => {
          dispatch(validateAndEditOrder(actionResult.data));
        });
      }

      if (orderMode === 'editSubscription') {
        SubscriptionService.getSubscription(order.id).then((actionResult) => {
          dispatch(startEditSubscription(actionResult.data));
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullCatalogLoaded]);

  useEffect(() => {
    if (isFirstRender && !router.query.share_cart) {
      if (!isLoggedIn) {
        showWelcomeDialog(() => dispatch(checkAndFetchBranchCatalogIfNecessary()));
        return;
      }
      if (orderMode === 'new' && !onClientBehalf) {
        checkActiveOrdersAndSubscriptions(() => {
          showWelcomeDialog(() => dispatch(introductoryPopupsCompletedAction(true)));
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let newPromotionsForBanners = promotionsForBanners;
    if (
      detailsAtStoreLevel &&
      detailsAtStoreLevel.ordersCount &&
      detailsAtStoreLevel.ordersCount >= 1
    ) {
      newPromotionsForBanners = promotionsForBanners.filter(
        (promotion) => promotion.promotionType.name !== 'forNewCustomer',
      );
    }

    setPromotionsForBannersToDisplay(newPromotionsForBanners);
  }, [detailsAtStoreLevel, promotionsForBanners]);

  useEffect(() => {
    if (isFirstRender && !isLoggedIn && router.query && router.query.showLoginDialog) {
      showDialog({
        dialogType: LOGIN_DIALOG,
      });
    }
  }, [isFirstRender, isLoggedIn, router.query, showDialog]);

  useEffect(() => {
    if (mainLayoutContext.userTopCartChoice) {
      mainLayoutContext.setIsOpenedTopCart(mainLayoutContext.userTopCartChoice === 'open');
      return;
    }
    mainLayoutContext.setIsOpenedTopCart(!homePageContent?.length);
  }, [homePageContent?.length, mainLayoutContext]);

  const topCart = useMemo(() => {
    return (
      <Box
        component="section"
        position="absolute"
        right={0}
        top={hidePromotionsBanner || !promotionsForBannersToDisplay.length ? 80 : 110}
      >
        <TopCart
          onExpanded={handleOnExpandTopCart}
          onContinueToCart={handleContinueToCart}
          isTopCartOpened={mainLayoutContext.isOpenedTopCart}
          showingPromotionsBanners={!hidePromotionsBanner && !!promotionsForBannersToDisplay.length}
          showBigHeader={!homePageId}
        />
      </Box>
    );
  }, [
    handleContinueToCart,
    handleOnExpandTopCart,
    hidePromotionsBanner,
    homePageId,
    mainLayoutContext.isOpenedTopCart,
    promotionsForBannersToDisplay.length,
  ]);

  return (
    <Box zIndex={4}>
      <section ref={mainLayoutContext.categoriesRef} className={classes.categoriesRoot}>
        {isMobile && (
          <MobileCategoryMenu menuItems={menuItemsFlattened} activeItem={activeMenuItem} />
        )}
        <Container maxContainerWidth fixed>
          <Box position="relative">
            {!isMobile && <CategoryMenu menuItems={menuItems} activeItem={activeMenuItem} />}
            {!isMobile && topCart}
          </Box>
        </Container>
      </section>
      {!hidePromotionsBanner && !!promotionsForBannersToDisplay.length && (
        <Box>
          <PromotionsBanner promotions={promotionsForBannersToDisplay} />
        </Box>
      )}
      <Box id="catalog" component="section" className={classes.childrenWrp}>
        {mainPageContent}
      </Box>
      {isMobile && (
        <>
          {isEmpty || !uniqueItemCount ? (
            <LoadPreviousOrderFixed />
          ) : (
            <Box component="section" position="sticky" bottom={0} zIndex={3}>
              <ContinueToPaymentFixed
                pinToBody={false}
                onContinueToPayment={handleContinueToCart}
                label={'button.goToCart'}
              />
            </Box>
          )}
        </>
      )}
      {isMobile && websiteDetails.websiteSettings.pwaEnabled && (
        <PWABannerPrompt onInstall={handleOnInstall} onClose={handleOnDismiss} />
      )}
    </Box>
  );
};

export default MainPagesWrapper;
