import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';
import { TStylesPromotionsBanner } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesPromotionsBanner> =>
    createStyles({
      promotionBannerWrapper: {
        minHeight: '60px',
        backgroundColor: theme.palette.grey[50],
        padding: theme.spacing(12 / 8),
        boxShadow: '0 2px 7px 0 rgba(0, 0, 0, 0.1)',
        marginBottom: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
          minHeight: 'auto',
          height: '40px',
          padding: theme.spacing(11 / 8, 0),
          boxShadow: '0 3px 15px 0 rgba(0, 0, 0, 0.1);',
        },
      },
      promotionBannerContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        height: '100%',
        alignItems: 'center',
        width: 'calc(100vw - 12px - 32px - 6px)', // 12-padding, 32-icon, 6-spacing between icon and text
        [theme.breakpoints.up('md')]: {
          width: '1240px',
          margin: '0 auto',
        },
      },
      hidePromotionBanner: {
        fontSize: '12px',
        marginLeft: theme.spacing(12 / 8),
        [theme.breakpoints.up('md')]: {
          marginLeft: 0,
        },
      },
      navigationButton: {
        display: 'none',
        padding: '0',
        [theme.breakpoints.up('md')]: {
          display: 'block',
          padding: theme.spacing(11 / 8),
        },
      },
      navigationIcon: {
        fontSize: '12px',
      },
      sliderContainer: {
        width: '100%',
        '& .swiper-slide': {
          width: 'calc(100vw - 12px - 32px - 6px) !important', // 12-padding, 32-icon, 6-spacing between icon and text
          visibility: 'hidden',
        },
        '& .swiper-slide-active': {
          visibility: 'visible',
        },

        [theme.breakpoints.up('md')]: {
          '& .swiper-slide': {
            width: '100% !important',
          },
          display: 'flex',
          justifyContent: 'space-between',
          overflow: 'visible',
          width: theme.direction === 'rtl' ? '1030px' : '1080px',
          margin: '0 auto',
        },
      },
      pagination: {
        width: theme.spacing(6 / 8),
        height: theme.spacing(6 / 8),
        backgroundColor: theme.palette.grey.A200,
        borderRadius: '50%',
        marginLeft: theme.spacing(6 / 8),
        [theme.breakpoints.up('md')]: {
          marginLeft: theme.spacing(12 / 8),
          backgroundColor: theme.palette.grey[200],
          width: theme.spacing(12 / 8),
          height: theme.spacing(12 / 8),
        },
      },
      clickablePagination: {
        zIndex: 'auto',
        display: 'flex',
        justifyContent: 'center',
        bottom: '-28px !important',
        width: '100vw !important',
        maxWidth: 'calc(100vw - 28px)', // to prevent horizontal scroll on ios
        right: theme.direction === 'rtl' ? '-21px !important' : '0',
      },
      swiper: {
        height: '100%',
        overflow: 'visible',
        width: '100%',
        '& > .swiper-wrapper': {
          display: 'flex',
          alignItems: 'center',
        },
        [theme.breakpoints.up('md')]: {
          overflow: 'hidden',
        },
      },
      activeBulletPagination: {
        width: theme.spacing(6 / 8),
        height: theme.spacing(6 / 8),
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderRadius: '50%',
        [theme.breakpoints.up('md')]: {
          width: theme.spacing(12 / 8),
          height: theme.spacing(12 / 8),
        },
      },
    }),
);
