import React, { FC, useCallback } from 'react';
import { IconButton } from '@material-ui/core';
import { uiStylesActions } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import { changeProductCardMode } from 'store/modules/analytics/actions';
import { getHomePageUiStyles } from 'store/modules/uiStyles/selectors';
import useStyles from 'ui/mobile/buttons/ProductCardViewMode/styles';
import Icon from 'ui/common/icons/Icon';
import { TProductCardViewMode } from './types';

const ProductCardViewMode: FC = () => {
  const dispatch = useDispatch();
  const { storeProductCardView } = useSelector(getHomePageUiStyles);

  const classes = useStyles({
    viewMode: storeProductCardView,
  });

  const handleViewModeBtnClick = useCallback(
    (mode: TProductCardViewMode) => {
      dispatch(
        uiStylesActions.homePageStylesUpdate({
          storeProductCardView: mode,
        }),
      );
      dispatch(changeProductCardMode({ viewMode: mode }));
    },
    [dispatch],
  );
  return (
    <>
      <IconButton
        className={classes.multipleViewModeBtn}
        onClick={() => handleViewModeBtnClick('multiple')}
        disableRipple
      >
        <Icon name="icon-view-mode-multiple" fontSize="inherit" />
      </IconButton>
      <IconButton
        className={classes.singleViewModeBtn}
        onClick={() => handleViewModeBtnClick('single')}
        disableRipple
      >
        <Icon name="icon-view-mode-single" fontSize="inherit" />
      </IconButton>
    </>
  );
};

export default ProductCardViewMode;
