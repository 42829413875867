import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';
import { TStylesStoreProductsVirtualizedList } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesStoreProductsVirtualizedList> =>
    createStyles({
      cardWrp: {
        display: 'flex',
        justifyContent: 'flex-start',
      },
      loadMoreButton: {
        width: '160px',
        [theme.breakpoints.up('md')]: {
          width: '150px',
        },
      },
      loadMoreButtonLabel: {
        fontSize: '16px',
        color: theme.palette.grey.A700,
      },
    }),
);
