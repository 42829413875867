import { alpha, createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';

import { TCartView } from 'hooks';
import { cardActionsHeight, headerHeight, topBlockHeight } from './constants';

import { TStylesTopCard } from './types';

export default makeStyles(
  (
    theme: Theme,
  ): StyleRules<
    TStylesTopCard,
    {
      expanded?: boolean;
      view: TCartView;
      isEmpty?: boolean;
    }
  > =>
    createStyles({
      root: {
        borderRadius: theme.spacing(1),
        width: theme.spacing(40), // 320px
        boxShadow: '0 5px 13px 0 rgba(0, 0, 0, 0.15)',
        overflow: 'initial',
      },
      rootDivider: {
        backgroundColor: theme.palette.grey.A100,
        // hide dividers that are a direct sibling to a ribbon (div).
        '&:has(+ div)': {
          display: 'none',
        },
        // hide dividers that are direct sibling of another divider.
        '&:has(+ hr)': {
          display: 'none',
        },
        // hide dividers that renders last and also direct sibling to a ribbon (div).
        '&:last-child:is(div + hr)': {
          display: 'none',
        },
      },
      incentiveDivider: {
        backgroundColor: theme.palette.grey.A100,
      },
      expandButton: {
        borderRadius: '50%',
        width: '24px',
        height: '24px',
        backgroundColor: theme.palette.grey[50],

        '&:hover': {
          backgroundColor: `${theme.palette.grey[50]} !important`,
        },
      },
      expandButtonLabel: {
        paddingTop: theme.spacing(0.25),
      },
      expand: {
        transform: 'rotate(0deg)',
        transition: theme.transitions.create('transform', {
          duration: theme.transitions.duration.shortest,
        }),
      },
      expandOpen: {
        transform: 'rotate(180deg)',
      },
      expandIcon: {
        fontSize: theme.spacing(1.5), // 12 px
      },
      topHeader: {
        height: headerHeight,
        backgroundColor: theme.palette.secondary.main,
        padding: theme.spacing(12 / 8, 16 / 8),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderRadius: ({ expanded }) => (expanded ? theme.spacing(1, 1, 0, 0) : theme.spacing(1)),

        '&:last-child': {
          paddingBottom: theme.spacing(1.5),
        },
      },
      rootButton: {
        minWidth: 'auto',
        padding: theme.spacing(0.625, 2, 0.875, 1.875), // '5px 16px 7px 15px'

        '&:disabled': {
          backgroundColor: alpha(theme.palette.background.paper, 0.2),
          opacity: '40%',
        },
      },
      buttonLabelWrp: {
        display: 'flex',
        flexDirection: 'column',
      },
      buttonLabelText: {
        lineHeight: `${theme.spacing(2.125)}px`, // '17px'
        textTransform: 'initial',
      },
      priceWithBasketBlock: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: theme.spacing(1.25), // 10px
      },
      priceText: {
        direction: 'rtl',
        lineHeight: `${theme.spacing(2.25)}px`, // 18px
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        textTransform: 'initial',
        maxWidth: theme.spacing(12), // 96px
        whiteSpace: 'nowrap',
        textAlign: 'end',
      },
      shoppingText: {
        fontSize: 14,
        fontWeight: 400,
        lineHeight: `${theme.spacing(2.75)}px`, // 22px
      },
      cardActionsRoot: {
        display: 'flex',
        height: cardActionsHeight,
        justifyContent: 'center',
        backgroundColor: theme.palette.grey[50],
        flexDirection: 'column-reverse',
        padding: theme.spacing(2, 2, 1.5, 2), //  '16px 16px 12px 16px',
        borderRadius: theme.spacing(0, 0, 1, 1),
      },
      bottomText: {
        marginTop: theme.spacing(1), // 8px
        lineHeight: `${theme.spacing(2.25)}px`, // 18px
        letterSpacing: '-0.2px',
      },
      mainRootContent: {
        overflow: 'auto',
        padding: 0,
        paddingLeft: theme.spacing(1),
        marginLeft: theme.spacing(-1),
      },
      rootEmpty: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      },
      emptyIcon: {
        fontSize: theme.spacing(25.375), // 203px
        marginLeft: theme.spacing(3.75), // 30px
      },
      mainEmptyText: {
        marginTop: theme.spacing(1.625), // 13px
        textAlign: 'center',
      },
      topBlockRoot: {
        height: topBlockHeight,
        background: theme.palette.grey[50],
      },
      emptyBasketImage: {
        width: 'auto',
        maxWidth: '60%',
        maxHeight: `calc(100% - 110px)`,
        minHeight: '60px',
      },
      icon: {
        fontSize: '16px',
      },
      iconLabel: {
        fontSize: theme.direction === 'rtl' ? '14px' : '13px',
        lineHeight: '14px',
        marginTop: '-4px',
      },
      backIcon: {
        transform: `rotate(${theme.direction === 'rtl' ? '180deg' : 0})`,
        marginRight: '6px',
      },
      topButtonsRoot: {
        cursor: 'pointer',
        display: 'block',
        padding: '0px',
      },
      topBlockWrapper: ({ isEmpty }) => ({
        padding: theme.spacing(4 / 8, 16 / 8, 7 / 8),
        display: 'flex',
        height: '100%',
        width: '100%',
        justifyContent: isEmpty ? 'center' : 'space-between',
      }),
      verticalDivider: {
        opacity: '0.46',
        borderLeft: '1px solid #c6cbcb',
        height: '34px',
        marginTop: theme.spacing(2 / 8),
      },
      incentivesWrapper: {
        minHeight: '68px',
        padding: '10px',
      },
    }),
);
