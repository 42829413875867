import { Box, Button } from '@material-ui/core';

import classNames from 'classnames';

import { useMobile, useRenderDate } from 'hooks';
import usePromotions from 'hooks/usePromotions';

import dynamic from 'next/dynamic';
import React, { FC, useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import Icon from 'ui/common/icons/Icon';
import Typography from 'ui/common/Typography/Typography';

import useStyles from './styles';
import { IPromotionSlide } from './types';

const Tooltip = dynamic(() => import('ui/common/Tooltip'));

const PromotionSlide: FC<IPromotionSlide> = ({ promotion, showTooltip, onTooltipClick }) => {
  const { isMobile } = useMobile();

  const { renderWeekdayShortNames } = useRenderDate();

  const { promotionText, primaryQuantityUnitText, promotionIconName } = usePromotions();

  const { t } = useTranslation();

  const classes = useStyles({ isMobile });

  const { relevantWeekdays } = promotion.parameters;

  const showReadMoreSection = useMemo(() => {
    return (
      promotion.promotionType.name === 'forCartEstimationBeforePreparation' ||
      promotion.promotionType.name === 'forCartEstimationAfterPreparation' ||
      promotion.promotionType.name === 'forSubscription' ||
      promotion.promotionType.name === 'forNewSubscription'
    );
  }, [promotion.promotionType.name]);

  const readMoreSection = useMemo(() => {
    if (!showReadMoreSection) return;

    const props = isMobile && {
      open: showTooltip,
      disableFocusListener: true,
      disableHoverListener: true,
      disableTouchListener: true,
    };

    return (
      <Tooltip
        {...props}
        initialClasses={{
          tooltip: classes.tooltip,
          popper: classes.popper,
          arrow: classes.arrow,
        }}
        placement={isMobile ? 'bottom-start' : 'top'}
        title={
          <Typography className={classes.tooltipDescription}>
            {`promotionsBanner.tooltip.${promotion.promotionType.name}`}
          </Typography>
        }
      >
        <Button
          disableRipple
          className={'padding-0'}
          onClick={() => isMobile && onTooltipClick && onTooltipClick(promotion.nonObfuscatedId)}
        >
          <>
            <Typography className={classNames(classes.dayOfWorkAndExpirationDay, classes.readMore)}>
              {'promotionsBanner.tooltip.readMore'}
            </Typography>
          </>
        </Button>
      </Tooltip>
    );
  }, [
    classes.arrow,
    classes.dayOfWorkAndExpirationDay,
    classes.popper,
    classes.readMore,
    classes.tooltip,
    classes.tooltipDescription,
    isMobile,
    onTooltipClick,
    promotion.nonObfuscatedId,
    promotion.promotionType.name,
    showReadMoreSection,
    showTooltip,
  ]);

  return (
    <Box className={classes.sliderWrapper}>
      <Box className={classes.discountTypeIconWrapper}>
        <Box className={classes.discountTypeIconBorder}>
          <Icon
            name={promotionIconName(promotion.discountType.name, promotion.parameters.orderType)}
            classes={{ root: classes.discountTypeIcon }}
          />
        </Box>
      </Box>
      <Box className={classes.descriptionWrapper}>
        <Typography
          className={classNames(classes.descriptionOfPromotion, 'font-family-rubik')}
          isTranslate={!promotion.bannerText}
        >
          {promotion.bannerText || promotionText(promotion)}&nbsp;&nbsp;
        </Typography>
        <Box className={classes.validationWrapper}>
          {relevantWeekdays && !!relevantWeekdays.length && relevantWeekdays.length < 7 && (
            <>
              <Typography
                className={classNames(classes.dayOfWorkAndExpirationDay, 'font-family-rubik')}
              >
                {`${t('promotionsBanner.validForDays')} ${renderWeekdayShortNames(
                  relevantWeekdays,
                )}`}
              </Typography>
              <Typography
                className={classNames(classes.dayOfWorkAndExpirationDay, 'font-family-rubik')}
              >
                &nbsp;&nbsp;|&nbsp;&nbsp;
              </Typography>
            </>
          )}
          {!!promotion.valueFormulaSourceQuantity &&
            promotion.discountType.name === 'itemRefund' && (
              <>
                <Typography
                  className={classNames(classes.dayOfWorkAndExpirationDay, 'font-family-rubik')}
                >{`${t('promotionsBanner.maxQuantityPerOrder', {
                  valueFormulaSourceQuantity: promotion.valueFormulaSourceQuantity,
                  unitName: primaryQuantityUnitText(promotion),
                })}`}</Typography>
                <Typography
                  className={classNames(classes.dayOfWorkAndExpirationDay, 'font-family-rubik')}
                >
                  &nbsp;&nbsp;|&nbsp;&nbsp;
                </Typography>
              </>
            )}
          <Typography
            className={classNames(classes.dayOfWorkAndExpirationDay, 'font-family-rubik')}
          >
            {`${t(
              `promotionsBanner.${
                relevantWeekdays && !!relevantWeekdays.length ? 'until' : 'validUntil'
              }`,
            )} ${promotion.expirationTime}`}
          </Typography>
          {showReadMoreSection && (
            <>
              <Typography
                className={classNames(classes.dayOfWorkAndExpirationDay, 'font-family-rubik')}
              >
                &nbsp;&nbsp;|&nbsp;&nbsp;
              </Typography>
              {readMoreSection}
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default PromotionSlide;
