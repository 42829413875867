import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';
import {
  ICategoryNameAndSort,
  TStylesICategoryNameAndSort,
} from 'components/common/CategoryNameAndSort/types';
import { MEDIUM_MOBILE_WIDTH } from 'components/common/CategoryNameAndSort/constants';

export default makeStyles(
  (
    theme: Theme,
  ): StyleRules<
    TStylesICategoryNameAndSort,
    { variant: ICategoryNameAndSort['variant']; isSearchValue: boolean }
  > =>
    createStyles({
      sortDropdownWrapper: ({ variant }) => ({
        width: variant === 'child' ? '48%' : 98,
        marginLeft: theme.spacing(6 / 8),
        [theme.breakpoints.up(MEDIUM_MOBILE_WIDTH)]: {
          width: variant === 'child' ? '48%' : '40%',
        },
        [theme.breakpoints.up('md')]: {
          width: 178,
        },
      }),
      categoryWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center',
        paddingBottom: ({ variant, isSearchValue }) =>
          theme.spacing(
            variant === 'fatherWithChild' && !isSearchValue
              ? 0
              : ((variant === 'fatherWithoutChild' || isSearchValue) && 20 / 8) || 16 / 8,
          ),
        [theme.breakpoints.up('md')]: {
          maxWidth: '898px',
          paddingBottom: ({ variant, isSearchValue }) =>
            theme.spacing(variant === 'fatherWithoutChild' || isSearchValue ? 34 / 8 : 0 / 8), // 20
        },
      },
      categoryTextWrapper: ({ variant }) => ({
        overflowWrap: 'anywhere',
        width: variant === 'fatherWithChild' ? '100%' : '50%',
        [theme.breakpoints.up('md')]: {
          width: '74%',
        },
      }),
      selectedCategoryName: ({ variant }) => ({
        fontSize: variant === 'child' ? '18px' : '22px',
        lineHeight: variant === 'child' ? '22px' : '24px',
        color: theme.palette.secondary.main,
        [theme.breakpoints.up('md')]: {
          lineHeight: variant === 'child' ? '34px' : '38px',
          fontSize: variant === 'child' ? '30px' : '36px',
        },
      }),
    }),
);
