import {
  Box,
  Button,
  IconButton,
  Portal,
  Slide,
  useScrollTrigger,
  useTheme,
} from '@material-ui/core';
import PWAApplePrompt from 'components/mobile/PWAApplePrompt';
import React, { FC, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  getIsIos,
  getIsPWAInstallDismissed,
  getIsPWAInstalled,
} from 'store/modules/config/selectors';

import Icon from 'ui/common/icons/Icon';
import Typography from 'ui/common/Typography';
import useStyles from './styles';
import { IPWABannerPrompt, THandleClose, THandleInstall } from './types';

const PWABannerPrompt: FC<IPWABannerPrompt> = ({ onInstall, onClose }) => {
  const classes = useStyles();
  const trigger = useScrollTrigger();
  const theme = useTheme();

  const isPWAInstalled = useSelector(getIsPWAInstalled);
  const isPWAInstallDismissed = useSelector(getIsPWAInstallDismissed);
  const isIOS = useSelector(getIsIos);

  const [showBannerPrompt, setShowBannerPrompt] = useState<boolean>(true);
  const [showIOSPrompt, setShowIOSPrompt] = useState<boolean>(false);

  const handleClose = useCallback<THandleClose>(
    (e) => {
      e.stopPropagation();
      if (onClose) {
        onClose(e);
      }
      setShowBannerPrompt(false);
    },
    [onClose],
  );

  const handleInstall = useCallback<THandleInstall>(() => {
    if (onInstall) {
      if (isIOS) {
        setShowIOSPrompt(true);
      } else {
        onInstall();
        setShowBannerPrompt(false);
      }
    }
  }, [isIOS, onInstall]);

  if (isPWAInstallDismissed || isPWAInstalled) {
    return null;
  }

  return (
    <Portal>
      <Box data-id="pwa-prompt">
        {showBannerPrompt && !showIOSPrompt && (
          <Slide in={!trigger} direction="up">
            <Button onClick={handleInstall} className={classes.installBtn}>
              <Box
                minHeight={46}
                height={1}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Icon name="icon-add" color="primary" classes={{ root: classes.addIcon }} />
                  <Typography
                    fontSize={14}
                    style={{
                      color: theme.palette.common.white,
                      fontWeight: 500,
                      lineHeight: '16px',
                      letterSpacing: 'normal',
                    }}
                  >
                    {'pwa.installerPromptText'}
                  </Typography>
                </Box>
                <IconButton onClick={handleClose} classes={{ root: classes.closeIcon }}>
                  <Icon name="icon-button-x" color="primary" style={{ fontSize: 12 }} />
                </IconButton>
              </Box>
            </Button>
          </Slide>
        )}
      </Box>
      {showIOSPrompt && (
        <PWAApplePrompt open={showIOSPrompt} onClose={() => setShowIOSPrompt(false)} />
      )}
    </Portal>
  );
};

export default PWABannerPrompt;
