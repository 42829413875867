import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';

import { TWithMobileStyles } from 'types';

import { TOrderDetailsReducerState } from 'store';
import { TStylesIMainPagesWrapper } from './types';

export default makeStyles(
  (
    theme: Theme,
  ): StyleRules<
    TStylesIMainPagesWrapper,
    TWithMobileStyles & {
      orderMode: TOrderDetailsReducerState['orderMode'];
      hidePromotionsBanner: boolean;
      promotionsForBannersLength?: number;
      isShowHomePage?: boolean;
    }
  > =>
    createStyles({
      categoriesRoot: ({ isMobile, orderMode }) => {
        let top;
        if (isMobile) {
          top = orderMode === 'new' ? 56 : 96;
        } else {
          top = orderMode === 'new' ? 80 : 120;
        }

        return {
          position: 'sticky',
          top,
          background: theme.palette.background.paper,
          zIndex: 4,
          boxShadow: '0 4px 10px 0 rgba(0, 0, 0, 0.06)',
        };
      },
      childrenWrp: ({ hidePromotionsBanner, promotionsForBannersLength, isShowHomePage }) => ({
        flex: 1,
        backgroundColor: theme.palette.background.default,
        zIndex: 3,
        minHeight: 500,
        paddingTop: isShowHomePage
          ? '14px'
          : (!hidePromotionsBanner && promotionsForBannersLength === 1 && '8px') || '22px',
        [theme.breakpoints.up('md')]: {
          paddingTop: '0px',
        },
      }),
    }),
);
