import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';
import { TStylesPromotionSlide } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesPromotionSlide> =>
    createStyles({
      sliderWrapper: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.up('md')]: {
          justifyContent: 'center',
        },
      },
      discountTypeIconWrapper: {
        width: '32px',
        height: '32px',
        marginRight: theme.spacing(6 / 8),
        [theme.breakpoints.up('md')]: {
          marginRight: 0,
          width: 'auto',
          height: 'auto',
        },
      },
      discountTypeIconBorder: {
        width: '32px ',
        height: '32px',
        backgroundColor: theme.palette.secondary.main,
        borderRadius: '50%',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        marginRight: theme.spacing(6 / 8),
        [theme.breakpoints.up('md')]: {
          width: 'auto',
          height: '40px',
          backgroundColor: 'transparent',
        },
      },
      discountTypeIcon: {
        color: theme.palette.primary.main,
        fontSize: '16px',
        [theme.breakpoints.up('md')]: {
          color: theme.palette.secondary.main,
          fontSize: '18px',
        },
      },
      descriptionWrapper: {
        [theme.breakpoints.up('md')]: {
          marginTop: theme.spacing(2 / 8),
          display: 'flex',
          alignItems: 'center',
        },
      },
      descriptionOfPromotion: {
        fontSize: '14px',
        lineHeight: '18px',
        fontWeight: 500,
        color: theme.palette.secondary.main,
      },
      dayOfWorkAndExpirationDay: {
        color: theme.palette.grey[600],
        fontSize: '12px',
        lineHeight: '16px',
        [theme.breakpoints.up('md')]: {
          fontSize: '14px',
          lineHeight: '18px',
        },
      },
      readMore: {
        textDecoration: 'underline',
      },
      tooltip: {
        padding: '8px !important',
        right: '9px',
        maxWidth: 'initial',
        width: 'calc(100vw - 28px) !important',
        backgroundColor: `${theme.palette.secondary.main} !important`,
        borderRadius: '2px',
        [theme.breakpoints.up('md')]: {
          right: '0',
          minWidth: theme.direction === 'rtl' ? 'initial !important' : '380px !important',
          maxWidth: theme.direction === 'rtl' ? 'initial !important' : '430px !important',
          width: theme.direction === 'rtl' ? '400px !important' : 'auto !important',
        },
      },
      popper: {
        top: '10px !important',
        zIndex: 1,
        [theme.breakpoints.up('sm')]: {
          zIndex: 6,
          top: '6px !important',
        },
      },
      arrow: {
        position: 'absolute',
        color: `${theme.palette.secondary.main}!important `,
      },
      tooltipDescription: {
        color: theme.palette.background.paper,
        fontSize: '14px',
        lineHeight: '16px',
      },
      validationWrapper: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        marginTop: theme.spacing(2 / 8),
        [theme.breakpoints.up('md')]: {
          marginTop: 0,
        },
      },
    }),
);
