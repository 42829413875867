import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';
import {
  ISSRStoreProductCard,
  TStylesSSRStoreProductCard,
} from 'components/common/seo/SSRStoreProductCard/types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesSSRStoreProductCard, ISSRStoreProductCard> =>
    createStyles({
      linkWrapper: {
        textDecoration: 'none',
      },
      root: {
        width: ({ width }) => (width ? `${width}px` : '100%'),
        height: theme.spacing(37.5), // 300px
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflow: 'visible',
        borderRadius: theme.spacing(0.75), // 6px
      },
      cardImageSkeleton: {
        backgroundColor: theme.palette.grey[100],
        width: theme.spacing(90 / 8),
        height: theme.spacing(90 / 8),
        marginTop: theme.spacing(14 / 8),
        marginBottom: theme.spacing(4 / 8),
        [theme.breakpoints.up('md')]: {
          marginTop: theme.spacing(4.75), // 38px
          marginBottom: theme.spacing(1.625), // 13px
        },
      },
      productType: {
        height: theme.spacing(23 / 8),
        position: 'absolute',
        top: 12,
        left: 12,
        [theme.breakpoints.up('md')]: {
          height: theme.spacing(27 / 8),
        },
      },
      mainBlock: {
        height: '150px',
        width: '120px',
        [theme.breakpoints.up('md')]: {
          width: 'auto',
        },
      },
      productName: {
        fontWeight: 'normal',
        fontSize: '14px',
        color: theme.palette.text.primary,
        marginBottom: theme.spacing(6 / 8),
        lineHeight: '16px',
        display: '-webkit-box',
        '-webkitLineClamp': 2,
        '-webkitBoxOrient': 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxHeight: '48px',
        [theme.breakpoints.up('md')]: {
          padding: '0 6px',
          lineHeight: '18px',
          marginBottom: theme.spacing(10 / 8),
          fontSize: '16px',
        },
      },
      relevancy: {
        backgroundColor: theme.palette.grey[100],
      },
      priceBlock: {
        backgroundColor: theme.palette.grey[100],
        marginTop: theme.spacing(19 / 8),
        padding: theme.spacing(0, 45 / 8),
        margin: '0 auto',
      },
      buttonsBlock: {
        backgroundColor: '#e2e2e2',
        marginTop: theme.spacing(34 / 8),
        [theme.breakpoints.up('md')]: {
          marginTop: theme.spacing(50 / 8),
        },
      },
    }),
);
