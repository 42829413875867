import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';
import { ICartItem, TStylesCartItem } from './types';

export default makeStyles(
  (
    theme: Theme,
  ): StyleRules<TStylesCartItem, ICartItem & { isFocus: boolean; isDeleteItem?: boolean }> =>
    createStyles({
      wrapper: {
        height: theme.spacing(12.5), // 100px
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        overflow: 'visible',
        position: 'relative',
      },
      main: {
        padding: theme.spacing(2.25, 1.25, 2.25), // 18px 10px 18px
        display: 'flex',
        justifyContent: 'space-between',
        height: theme.spacing(100 / 8),
        width: '100%',
        opacity: ({ isDeleteItem }) => (isDeleteItem ? 0.12 : 1),
      },
      deleteSuccessOverlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
      },
      productDetails: {
        display: 'flex',
        justifyContent: 'space-between',
        marginLeft: theme.spacing(12 / 8),
        flex: 1,
      },
      tag: {
        position: 'absolute',
        left: 0,
        top: 6,
        zIndex: 1,
      },
      imageWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: theme.spacing(0.75), // 6px
      },
      description: {
        display: 'flex',
        alignItems: 'center',
        flex: 1,
      },
      cardImage: {
        width: theme.spacing(8.75), // 70px
        height: theme.spacing(6.5), // 52px
      },
      price: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginLeft: theme.spacing(1.5), // 12px
        width: theme.spacing(8.125), // 65px
      },
      quantity: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: theme.spacing(0.5), // 4px
      },
      quantityValue: ({ isFocus }) => ({
        width: theme.spacing(6.5), // 52px
        display: isFocus ? 'none' : 'block',
      }),
      quantityButton: ({ isFocus }) => ({
        width: theme.spacing(6.5), // 52px
        display: isFocus ? 'block' : 'none',
      }),
      descriptionText: {
        fontSize: theme.spacing(14 / 8),
        display: '-webkit-box',
        lineHeight: `${theme.spacing(2)}px`, // 16px
        '-webkitLineClamp': 3,
        '-webkitBoxOrient': 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: theme.spacing(80 / 8),
        maxHeight: theme.spacing(8), // 64px
        textAlign: 'initial',
      },
      deleteButtonRoot: ({ isFocus, bagOfProducts, bagOfProductsJson }) => ({
        borderRadius: '50%',
        display: isFocus ? 'flex' : 'none',
        backgroundColor: theme.palette.error.main,
        width: theme.spacing(2.5), // 20px
        height: theme.spacing(2.5), // 20px
        position: 'absolute',
        left: 8,
        top: bagOfProducts && bagOfProductsJson?.maxNumOfReplacements ? 20 : 40,
        '&:hover': {
          backgroundColor: `${theme.palette.error.dark} !important`,
        },
        '& .MuiTouchRipple-root span': {
          backgroundColor: '#000 !important',
          opacity: '0.1 !important',
        },
        zIndex: 1,
      }),
      editBasket: ({ isFocus }) => ({
        borderRadius: '50%',
        display: isFocus ? 'flex' : 'none',
        width: theme.spacing(2.5), // 20px
        height: theme.spacing(2.5), // 20px
        position: 'absolute',
        left: 8,
        top: 54,
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
          backgroundColor: `${theme.palette.primary.dark} !important`,
        },
        '& .MuiTouchRipple-root span': {
          backgroundColor: '#000 !important',
          opacity: '0.1 !important',
        },
        zIndex: 1,
      }),
      editBasketIcon: {
        fontSize: theme.spacing(10 / 8),
      },
      deleteIconRoot: {
        fontSize: theme.spacing(1.125), // 9px
      },
      productDetailsButton: {
        padding: '0px !important',
        width: 'inherit',
      },
    }),
);
