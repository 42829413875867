import { createStyles, makeStyles, StyleRules } from '@material-ui/core';
import { TStylesStoreProductsListSSR } from 'components/common/StoreProductsListSSR/types';

export default makeStyles(
  (theme): StyleRules<TStylesStoreProductsListSSR> =>
    createStyles({
      wrapper: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
      },
      itemWrapper: {
        marginBottom: '20px',
        width: 'calc(50% - 10px)',
        [theme.breakpoints.up('md')]: {
          width: 'auto',
        },
      },
    }),
);
